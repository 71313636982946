@import '_vars';

// Main app layout holder
$m: module-sidebar;

.#{$m} {
  @include box-primary(false, 25px, 25px, 25px, 25px);
  max-width: $sidebar-width;
  width: 100%;
  overflow-y: auto;
  height: max-content;
  height: calc(100vh - #{$header-plus-footer-height});
  padding-bottom: 5px;

  @include max-screen($xxlg - 1) {
    z-index: 1000;
  }

  @include max-screen($xxlg - 1) {
    position: fixed;
    top: 70px;
    left: 0;
    -webkit-box-shadow: $mobile-box-shadow;
    -moz-box-shadow: $mobile-box-shadow;
    box-shadow: $mobile-box-shadow;
    margin: 0 !important;
    border: 0 !important;
    height: 100vh;
    border-radius: 0 !important;
    overflow: auto;
  }

  @include max-screen-height(667px) {
    height: calc(100vh - 70px);
    padding: 10px 25px 25px 25px;
  }

  @include min-screen($xxlg) {
    min-width: $sidebar-width;
    margin-right: 20px;
  }

  @include screen-height(667px, $md + 1) {
    padding: 10px 25px 0 25px;
  }

  .settings-wrapper {
    @include min-screen-height(1000px) {
      min-height: 750px;
    }
    .form__fieldset {
      @include screen-height($md, $md-900) {
        margin: 0 0 10px 0;
      }
      &.date-options-wrapper {
        display: flex;
        .date-option-wrapper {
          width: calc(50% - 7.5px);
          margin-right: 15px;
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }

      &.stop-reason {
        label.label {
          font-weight: $bold;
          font-size: $caption;
          line-height: 18px;
          color: $black-mine-shaft;
          text-overflow: ellipsis;
          height: 15px;
          white-space: nowrap;
          margin-bottom: 15px;
        }

        .stop-reason-wrapper {
          display: flex;
          flex-wrap: wrap;
          .reason {
            width: 50%;
          }
        }

        .mat-checkbox-layout {
          margin-bottom: 0;
        }
      }
      &.option-slider-wrapper {
        margin: 0 0 2px 0;
        .sb-slide-toogle-wrapper {
          align-items: flex-end;
          margin-bottom: 0;
        }
      }
    }
  }

  &--admin {
    padding: 20px;
    height: 100%;
    jm-button {
      &.active-link {
        button {
          background-color: $cinnabar-red;
          border: 1px solid $cinnabar-red;
          span {
            color: $base !important;
          }

          i {
            color: $base !important;
          }
          .img-icon {
            svg path {
              fill: $base !important;
            }
          }
        }
      }
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &--article_templates {
    position: relative;
    top: 0;
    box-shadow: none;
    margin-right: 20px !important;
    border: 1px solid $border-color !important;
    border-radius: 5px !important;
    height: calc(100vh - #{$header-plus-footer-height});
    min-width: 265px;
    @include media(mobile tablet) {
      display: none !important;
    }

    .section-wrapper {
      height: auto;
    }
  }

  &--dashboard {
    &.embedded {
      top: 90px;
    }
  }
}
