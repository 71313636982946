//calendar custom styling

.mat-calendar {
  &-body-selected {
    background-color: $mantis-green;
    color: #fff;
  }

  &-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: rgba($mantis-green, 0.25);
  }
  &-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background: rgba($mantis-green, 0.25);
  }
  &-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $light-gray;
  }
}
