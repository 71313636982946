@import '_vars';

.mat-mdc-snack-bar-container {
  padding: 0 !important;
  border-radius: $box-border-radius;
  overflow: hidden;
  color: $black-mine-shaft;
  .mdc-snackbar__surface {
    background-color: $base !important;
    padding: 0 !important;

    .mat-mdc-snack-bar-label {
      padding: 0 !important;
    }

    .snack-bar__header {
      height: $snackbar-header-height;
      color: $base;
      display: flex;
      align-items: center;
      padding: 0 $section-spacing;

      &.success {
        background-color: $mantis-green;
      }
      &.error {
        background-color: $jm-red;
      }
      &.warning {
        background-color: $yellow-1;
      }

      .header__text {
        margin-left: 10px;
      }
    }

    .snack-bar__body {
      padding: $section-spacing;
      max-width: $snackbar-max-width;
      font-size: $label;
      color: $black-mine-shaft;

      span {
        font-weight: $bold;
        &.link {
          color: $jm-red;
          font-size: $label;
          font-weight: $bold;
          line-height: $lh-default;
          margin-top: $inner-spacing;
          display: flex;
          align-items: center;
          gap: $inner-spacing;
          svg {
            fill: $jm-red;
          }
          &:hover {
            color: $mantis-green;
            cursor: pointer;
            svg path {
              fill: $mantis-green;
            }
          }
        }
      }
    }

    .dismiss {
      background: $black-mine-shaft;
      color: $base;
    }

    .snack-bar__actions {
      display: flex;
      gap: $inner-spacing;
      padding: 0 $section-spacing $section-spacing $section-spacing;

      button {
        padding: 6px 10px;
        min-height: auto;
        min-width: auto;
        height: 32px;
      }

      button.readmore {
        background-color: $base;
        border: $border-width solid $black-mine-shaft;
        span {
          color: $black-mine-shaft;
        }
      }
    }
  }
}
