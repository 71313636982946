@import 'media-blender';

//global font size
$font-size: 16px;

// Font weights
$light: 300;
$normal: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;
$heavy: 900;

//font sizes
$heading-xl: 42px;
$heading-large: 30px;
$heading: 24px;
$title: 18px;
$body: 16px;
$label: 14px;
$caption: 12px;
$table-tag: 11px;
$caption_small: 10px;

/**************************************************
  COLORS
**************************************************/

$base: #ffffff;
$gray: #888888;
$black-mine-shaft: #2c3033;
$main-text-color: #929ea7;
$black: #000000;
$alice-gray: #eff3f6;
$light-gray: #f2f1f1;
$jm-red: #ef4123;
$mantis-green: #80c953;
$feijoa-green: #b1dd96;
$tooltip-color: rgba(41, 41, 41, 0.8);
$silver-chalice: #a6a6a6;
$whisper-gray: #fdfdfe;
$saffron: #f6ca30;
$cadet-gray: #aab9c3;
$catskill-white: #f9fbfc;
$nevada-gray: #66686a;
$label-gray: #757575;
$base-red: #e2001a;
$base-gray: #f9f9f9;
$smothie-gray: #656565;
$mystic-gray: #dee6ec;
$cinnabar-red: #ef4124;
$regent-gray: #8c979e;
$agua-haze: #f7f9fa;
$trout: #4f5a62;
$heather-gray: #c3ced6;
$light-red: #fef5f4;
$dark-coral: #d25051;
$bright-cerulean: #0daed3;
$orange: #eb9d27;
$soft-green: #a0d759;
$frost-green: #f2faee;

//notice colors
$error: #ef4123;
$success: #80c953;
$warning: #f9b022;
$info: #14a2ef;
$error-bg: #fef5f4;
$success-bg: #f8fcf6;
$warning-bg: #fefbf4;
$info-bg: #f1fafe;

//input colors
$input-border: #c3ced6;
$input-border-disabled: $mystic-gray;
$input-placeholder: #8d979e;

// yellow pallete
$yellow-1: #f9b022;

//shadows
$mobile-box-shadow: 34px 0 18px -18px rgba($black, 0.29);

//chart circles
$circle-active: #00aaee;
$circle-inactive: #002266;

//scrollbar color
$scrollbar-color: #aab9c2;
$scrollbar-width: 8px;
$track-color: $catskill-white;
$scrollbar-thumb-border-radius: 10px;

// Grid & Layout breakpoints:
$container-max-width: 1440px;
$sidebar-width: 285px;

//button disabled color
$button-disabled-text: #9da6ac;

//old layout breakpoints
$xs: 320px;
$mobile-small: 360px;
$sm: 480px;
$md: 768px;
$md-900: 900px;
$lg: 992px;
$desktop: 1024px;
$xlg: 1200px;
$xxlg: 1366px;
$xxxlg: 1920px;

//new layout breakpoints
$media-breakpoints: (
  mobile: 0 767,
  tablet: 768 1023,
  desktop: 1024 3840,
);

//animation timings
$anim-time: 0.25s;

//layout vars
$sidebar-width: 265px;

//forms spacing
$input-sm-spacing: 10px;
$input-md-spacing: 20px;

//spacing
$xs-spacing: 5px;
$md-spacing: 30px;
$lg-spacing: 50px;
$inner-spacing: 10px;
$section-spacing: 20px;
$filter-box-spacing: 20px;
$elements-spacing: 15px;

//standard border
$border-width: 1px;
$border-width-fat: 2px;
$border-color: #d6dee4;

//line height
$lh-small: 1.25;
$lh-default: 1.5;

//border-radiuses
$box-border-radius: 5px;

//nav mobile/small height
$navbar-mobile-height: 70px;
$navbar-logo-mobile-size: 42px;
$navbar-logo-desktop-size: 48px;

//notification bar height
$notification-bar-height: 50px;

//footer height
$footer-height: 64px;

//header height
$header-height: 80px;

//Default height for all form elements
$input-height: 42px;

//default height for all table rows except notification
$row-height: 48px;
$notification-row-height: 60px;

//default buttons
$button-height: 42px;
$button-spacing: 15px;
$button-spacing-mobile: 10px;
$small-button-width: 42px;
$button-min-width: 90px;
$button-max-width: 150px;
$button-small-height: 32px;
$button-icon-size: 16px;
$button-vertical-padding-mobile: 3px;
$button-dashboard-min-width: 165px;

//show more
$more-options-btn-sizing: 36px;

//filters height
$filters-height: 36px;

//search-bar
$search-bar-height: 60px;

//tabs
$tab-height: 60px;

//progress bar
$progress-bar-height: 24px;

//dashboard bar
$dashboard-bar-height: 60px;

//default section title height
$section-title-height: 60px;

//box
$box-header-height: 60px;

//data info box height:
$data-info-box-height: 70px;
$data-info-box-border-width: 6px;

//breakpoint for showing dashboard mobile menu
$dashboard-mobile-menu-breakpoint: 768px;

//main content padding
$main-content-padding: 40px;

//modal large padding
$modal-size-desktop: 1024px;
$modal-spacing: 20px;
$modal-spacing-md: 40px;
$modal-spacing-large: 50px;
$modal-spacing-xl: 60px;
$modal-desktop-min-height: 600px;
$modal-tutorial-icon-sizing: 36px;

//toggle component width
$toggle-width: 71px;

//header + footer + spacing
$header-plus-footer-height: $footer-height + $header-height + $main-content-padding;

//sticky scroll offsets
$boxes-offset: 180px;
$main-offset: $header-height + 20px; //used on sidebar and upper header
$notification-active-offset: 150px;
$gallery-offset: 180px;
$gallery-table-offset: 244px;
$notification-table-mobile-offset: 350px;

//embedded sticky scroll offsets
$boxes-offset-embedded: 100px;
$main-offset-embedded: 20px; //used on sidebar and upper header
$notification-active-offset-embedded: 70px;
$gallery-offset-embedded: 100px;
$gallery-table-offset-embedded: 164px;
$notification-table-mobile-offset-embedded: 270px;

//datepicker vars
$calendar-width: 320px;
$datepicker-calendar-width: 280px;
$datepicker-date-cell-size: 2.25rem;
$datepicker-date-cell-padding: 0.05rem;

//dashboard sensors
$sensor-box-desktop-width: 160px;

//dropdown option sizing
$option-icon-size: 16px;
$option-icon-size-lg: 24px;

//jm table options
$table-xs-cell-width: 70px;
$table-small-cell-width: 100px;
$table-medium-cell-width: 150px;
$table-large-cell-width: 250px;
$table-extra-large-cell-width: 300px;
$table-header-height: 48px;
$table-header-shadow-height: 10px;
$table-border-radius: $box-border-radius;
$toggle-item-width: 54px;
$table-row-height: 60px;
$table-cell-padding: 5px;
$table-filter-width: 320px;
$expand-arrow-dimension: 24px;
$table-cell-spacing: 20px;
$table-cell-mobile-height: 18px;

//filter states
$filter-active-bg: rgba(140, 201, 83, 0.2);
$filter-hover-bg: rgba(146, 158, 167, 0.2);

$jm-background-light: rgba(239, 65, 35, 0.05);

//impersonation menu
$impersonation-menu-width: 192px;
$impersonation-menu-decoration-height: 3px;
$impersonation-menu-decoration-radius: 6px;
$impersonation-icon-width: 42px;
$impersonation-icon-height: 42px;
$impersonation-icon-big-circle-sizing: 38.2px;
$impersonation-icon-medium-circle-sizing: 35px;
$impersonation-icon-small-circle-sizing: 30px;
$impersonation-link-icon-width: 16px;
$impersonation-link-icon-height: 12px;

//widgets vars
$widget-footer-height: 60px;
$widget-tag-min-width: 150px;
$widget-tag-icon-width: 12px;
$widget-tag-icon-spacing: 10px;
$widget-tag-vertical-padding: 6px;
$widget-gap: 40px;
$widget-drag-icon-height: 16px;
$widget-main-spacing: $section-spacing;
$widget-badge-horizontal-padding: 4px;
$widget-badge-vertical-padding: 2px;
$widget-badge-height: 16px;

//performance dashboard
$pd-widget-gap: 56px;
$pd-widget-loader-height: 82px;
$pd-graph-height: 300px;
$pd-widget-box-gap: 20px;

//widget modal
$add-widget-modal-sidebar-width: 300px;
$add-widget-modal-main-controls-height: 556px;
$add-widget-modal-main-controls-height-mobile: 300px;

//widgets main nav navigation
$widgets-list-height: 200px;
$widgets-list-desktop-height: 300px;
$widgets-list-width: 300px;
$widget-list-option-height: 42px;

//dashboard
$dashboard-period-control-wrap-min-width: 150px;

//notifications
$snackbar-max-width: 280px;
$snackbar-header-height: 48px;
$no-notifications-holder-height: 250px;
$no-notifications-icon-sizing: 58px;

//access keys
$access-key-img-size: 55px;
$access-key-btn-max-width: 180px;

//sample image
$sample-img-sizing: 90px;
$sample-img-gap: 20px;

//machine graphs
$mg-icon-size: 34px;
$mg-vertical-line-height: 36px;

// selectable list items
$selectableHover: #f9fbfc;
$selectableSelected: #f5fbf1;
$selectableSelectedHover: #e0f2d6;

//notice component
$notice-icon-sizing: 16px;

//media folders
$folder-sizing: 36px;
$folder-height: 50px;

//image gallery
$media-gallery-offset: 350px;

// percentage colors
$success-100: $mantis-green;
$success-90: #a4d840;
$success-80: #c9e144;
$success-70: #e8ed46;
$success-60: #fff24d;
$success-50: #ffca3e;
$success-40: #ffb131;
$success-30: #f58e24;
$success-20: #ef6123;
$success-10: $jm-red;

//articles
$article-note-title-size: 36px;
$article-empty-box-min-height: 243px;
$article-action-btn-holder-height: 80px;
$article-section-min-height: 51px;
$article-section-title-spacing-left: 13px;
$article-section-title-spacing-right: 8px;

//raw events table
$rw-container: 1460px;

//push notification settings
$pn-settings-value-width: 71px;
$pc-icon-sizing: 20px;

//sensors
$sensor-color-sign-sizing: 30px;
$sensor-circle-animation-middle-circle: 22px;
$sensor-circle-animation-small-circle: 12px;
$tag-value-height: 20px;

//realtime sensor
$realtime-sensor-grid-gap: 10px;
$tag-value-max-width: 120px;
$num-value-max-width: 150px;

//orders table
$orders-offset-mobile: 340px;
$orders-offset-tablet: 488px;
$orders-min-height-tablet: 400px;
$orders-table-offset-desktop: 578px;

//orders
$article-icon-sizing: 64px;
$article-pdf-preview-sizing: 50px;
$article-img-preview-icon-sizing: 16px;

//dialog
$dialog-width-mobile-tablet: 95vw;
$dialog-height-desktop: 720px;

//machine statistics
$machine-statistics-card-min-height: 90px;

//machine status component
$ms-text-sizing: 11px;
$ms-wrapper-height: 20px;
