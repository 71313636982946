@import '_vars';

$color_1: #999;
$color_2: #d0021b;
$color_3: rgba(0, 0, 0, 0.54);
$color_4: rgba(0, 0, 0, 0.87);
$color_5: #333;
$color_6: #ccc;
$background_color_2: #fff;
$background_color_3: #d8d8d8;
$background_color_4: #d8d8d8;
$background_color_5: #d8d8d8;
$border_color_2: transparent transparent #999;
$border_color_3: transparent transparent #333;
$border_color_4: #007eff;
$border_color_5: #999 transparent transparent;
$border_top_color_1: #666;
$border_top_color_2: #e6e6e6;
$border_bottom_color_1: #e6e6e6;

$text-color: #8888;
$arrow-color: #e7e7e7;
$border_color_1: #979797;
$background_color_1: #d8d8d8;
.ng-select {
  height: $input-height;
  max-width: 100%;
  &.ng-select-single .ng-select-container {
    min-height: $input-height;
    max-height: $input-height;
    border-radius: 0;
  }
}
.ng-select.ng-select-opened {
  > .ng-select-container {
    background: #fff;
    border-color: $border_color_1;
    &:hover {
      box-shadow: none;
    }
    .ng-arrow {
      border-color: transparent transparent $arrow-color;
      border-width: 0 7px 11px;
      border-style: solid;
      top: 1px;
    }
  }
}
.ng-select.ng-select-opened.ng-select-bottom {
  > .ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.ng-select.ng-select-opened.ng-select-top {
  > .ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
.ng-select.ng-select-focused {
  &:not(.ng-select-opened) {
    > .ng-select-container {
      border-color: $border_color_4;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    }
  }
}
.ng-select.ng-select-disabled {
  > .ng-select-container {
    background-color: $background_color_1;
  }
}
.ng-select {
  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }
  .ng-select-container {
    background-color: $background_color_2;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center;

    .ng-value-container {
      align-items: center;
      padding-left: 5px;
      .ng-placeholder {
        color: $text-color;
      }
      .ng-value-label {
        color: $text-color;
      }
    }
  }
  .ng-clear-wrapper {
    color: $color_1;
    &:hover {
      .ng-clear {
        color: $color_2;
      }
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    .ng-arrow {
      top: 1px;
      border-color: $arrow-color transparent transparent;
      border-width: 11px 7px 0px;
      border-style: solid;
    }
  }
}
.dropdown-head {
  outline: none;
  border: none;
  &.ng-select-opened > .ng-select-container {
    .ng-arrow {
      top: 3px;
      border-color: transparent transparent #fff;
      border-width: 0 6px 10px;
    }
  }
  .ng-arrow-wrapper {
    background: $base-red;
    color: $base;
    padding: 3px 7px;
    .ng-arrow {
      top: 1px;
      border-color: $base transparent transparent;
      border-width: 10px 6px 0;
    }
  }
}

[dir='rtl'] {
  .ng-select {
    .ng-select-container {
      .ng-value-container {
        padding-right: 10px;
        padding-left: 0;
      }
    }
    .ng-spinner-zone {
      padding: 5px 0 0 5px;
    }
    .ng-arrow-wrapper {
      padding-left: 5px;
      padding-right: 0;
    }
  }
  .ng-select.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          padding-right: 10px;
          padding-left: 50px;
        }
      }
    }
  }
  .ng-select.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        padding-right: 7px;
        padding-left: 0;
        .ng-value {
          margin-right: 0;
          margin-left: 5px;
          .ng-value-icon.left {
            border-left: 1px solid #b8dbff;
            border-right: none;
          }
          .ng-value-icon.right {
            border-left: 0;
            border-right: 1px solid #b8dbff;
          }
        }
        .ng-value.ng-value-disabled {
          .ng-value-label {
            padding-left: 0;
            padding-right: 5px;
          }
        }
        .ng-input {
          padding: 0 3px 3px 0;
        }
        .ng-placeholder {
          padding-right: 3px;
          padding-left: 0;
        }
      }
    }
  }
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option.ng-option-child {
        padding-right: 22px;
        padding-left: 0;
      }
      .ng-option {
        .ng-tag-label {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
    direction: rtl;
    text-align: right;
  }
}
.ng-select.ng-select-single {
  .ng-select-container {
    height: 36px;
    .ng-value-container {
      .ng-value {
        margin-top: 2px;
      }
      .ng-input {
        top: 5px;
        left: 0;
        padding-left: 10px;
        padding-right: 50px;
      }
    }
  }
}
.ng-select.ng-select-multiple.ng-select-disabled {
  > .ng-select-container {
    .ng-value-container {
      .ng-value {
        background-color: $background_color_1;
        border: 1px solid #e6e6e6;
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
  }
}
.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      padding-top: 5px;
      padding-left: 7px;
      .ng-value {
        font-size: 0.9em;
        margin-bottom: 5px;
        background-color: $background_color_3;
        border-radius: 2px;
        margin-right: 5px;
        .ng-value-label {
          display: inline-block;
          padding: 1px 5px;
        }
        .ng-value-icon {
          display: inline-block;
          padding: 1px 5px;
          &:hover {
            background-color: $background_color_4;
          }
        }
        .ng-value-icon.left {
          border-right: 1px solid #b8dbff;
        }
        .ng-value-icon.right {
          border-left: 1px solid #b8dbff;
        }
      }
      .ng-value.ng-value-disabled {
        background-color: $background_color_1;
        .ng-value-label {
          padding-left: 5px;
        }
      }
      .ng-input {
        padding: 0 0 3px 3px;
      }
      .ng-placeholder {
        top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
      }
    }
  }
}
.ng-dropdown-panel {
  background-color: $background_color_2;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.06);
  left: 0;
  .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px;
    &:empty {
      display: none;
    }
  }
  .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    margin-bottom: 1px;
    max-height: 130px;
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      color: $color_3;
      cursor: pointer;
    }
    .ng-optgroup.ng-option-disabled {
      cursor: default;
    }
    .ng-optgroup.ng-option-marked {
      background-color: $background_color_1;
    }
    .ng-optgroup.ng-option-selected {
      background-color: $background_color_1;
    }
    .ng-optgroup.ng-option-selected.ng-option-marked {
      background-color: $background_color_1;
    }
    .ng-option {
      max-height: 22px;
      line-height: 17px;
      margin: 3px 8px;
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
    .ng-option.ng-option-selected {
      background-color: $background_color_1;
    }
    .ng-option.ng-option-selected.ng-option-marked {
      background-color: $background_color_1;
    }
    .ng-option.ng-option-marked {
      background-color: $background_color_5;
      color: $color_5;
    }
    .ng-option.ng-option-disabled {
      color: $color_6;
    }
    .ng-option.ng-option-child {
      padding-left: 22px;
    }
  }
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: $border_top_color_2;
  margin-top: -1px;
  .ng-dropdown-panel-items {
    .ng-option {
      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: $border_bottom_color_1;
  margin-bottom: -1px;
  .ng-dropdown-panel-items {
    .ng-option {
      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }
}

@media (min-width: 1700px) {
  .ng-select {
    max-width: 200px;
  }
}

.shift-wrapper {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
  }
}
