@import '_vars';
@import '_mixin';

.articles-list {
  display: grid;
  grid-gap: $section-spacing;
  grid-template-columns: 1fr;
  height: 100%;
  @include media(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media(desktop up) {
    height: auto;
    overflow: auto;
    grid-template-columns: repeat(4, 1fr);
  }
  .jm-card {
    height: 120px;
    padding: 0px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: 0.25s all ease-in-out;
    @include media(tablet up) {
      height: 140px;
    }

    .library-name {
      flex: 1 1 0;
      padding: 20px;

      p {
        font-weight: $bold;
        font-size: $title;
        line-height: 1.5;
        color: $black-mine-shaft;
        @include text-ellipsis($lines-displayed: 3);
      }
    }

    .delete-library {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 0 1 76px;
      background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
      border-left: $border-width solid $border-color;
      border-radius: 0px $box-border-radius $box-border-radius 0px;
      padding-top: 25px;
      .delete-icon {
        background: $base;
        border: $border-width solid $border-color;
        border-radius: $box-border-radius;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(0.25s);

        svg {
          path {
            color: $main-text-color;
            @include transition(0.25s);
          }
        }
        &:hover {
          border-color: $cinnabar-red;
          cursor: pointer;
          svg {
            path {
              fill: $cinnabar-red;
            }
          }
        }
      }
    }

    &:hover {
      border: $border-width solid $mantis-green;
      box-sizing: border-box;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.09);
      .library-name {
        color: $main-text-color;
      }
    }
  }
}
