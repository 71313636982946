// tag module
$m: tag;

.#{$m} {
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid;

  &__title {
    color: $base;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: $extra_bold;
  }

  &--active,
  &--Active {
    border-color: $mantis-green;
    .#{$m} {
      &__title {
        color: $mantis-green;
      }
    }
  }

  &--expired,
  &--inactive,
  &--Inactive {
    border-color: $jm-red;
    .#{$m} {
      &__title {
        color: $jm-red;
      }
    }
  }

  &--upcoming {
    border-color: $cadet-gray;
    .#{$m} {
      &__title {
        color: $cadet-gray;
      }
    }
  }
}
