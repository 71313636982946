.quick-form {
  .upload-box {
    @include flex-center-center;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    background-color: $catskill-white;
    border: 1px dashed $border-color;
    border-radius: 5px;
    text-align: center;
    position: relative;
    padding: 20px;

    ::ng-deep {
      jm-button {
        z-index: 10;
        @include max-screen($lg - 1) {
          width: 100%;
          max-width: 100%;
        }

        .button {
          @include max-screen($lg - 1) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    .upload-intro {
      margin-bottom: 15px;
      p {
        margin-bottom: 0px;
        font-size: $caption;
        color: $black-mine-shaft;
        @include min-screen($lg) {
          font-size: $body;
        }
        &.upload-title {
          @include max-screen($lg - 1) {
            display: none;
          }
        }
      }
    }
    input.file {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    &.disable-box {
      pointer-events: none;
      cursor: not-allowed;
    }
    &.invalid {
      border-color: 1px solid $jm-red;
    }
    &:hover {
      cursor: pointer;
      border-color: $mantis-green;
    }
  }

  .ng-touched.ng-invalid > .upload-box {
    border: 1px solid $jm-red;
  }

  .upload-preview {
    @include box-primary(true);
    display: flex;
    justify-content: space-between;

    @include max-screen($md - 1) {
      padding: 10px;
    }
    .image-info {
      display: flex;
      align-items: center;
      .image-preview-box {
        width: 42px;
        height: 42px;
        max-width: 42px;
        max-height: 42px;
        margin-right: 15px;

        @include min-screen($md) {
          width: 90px;
          height: 90px;
          max-width: 90px;
          max-height: 90px;
        }
        .preview {
          width: 100%;
          height: 100%;
          object-fit: contain;

          &:hover {
            cursor: zoom-in;
          }
        }

        &--pattern {
          .preview {
            &:hover {
              cursor: initial;
            }
          }
        }
        &--default-image {
          padding: 24px;
          background-color: $alice-gray;
          margin-right: 20px;
          img {
            width: 48px;
            height: 48px;
          }
        }
      }
      .image-data {
        @include min-screen($lg) {
          padding: 10px 0;
        }
      }
      p {
        margin-bottom: 5px;
        color: $black-mine-shaft;
        &.image-title {
          font-size: $caption;
          line-height: 15px;

          @include max-screen($lg - 1) {
            display: none;
          }
          span.user {
            color: $jm-red;
            font-weight: $bold;
          }
        }
        &.image-name {
          font-weight: $bold;
          font-size: $label;
          line-height: 1.45;
          margin: 10px 0;

          @include min-screen($lg) {
            font-size: $body;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 20px;
  }
}
