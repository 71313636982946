.p-dropdown {
  font-size: $label;
  font-family: $font-family-base;
  margin: $filter-box-spacing 0;
  background: $catskill-white;
  border: $border-width solid $input-border;
  border-radius: $box-border-radius;
  padding: 1.5px 0 1.5px 7px;
  transition: border-color 0.2s ease-in-out;
  box-shadow: none;
  outline: none;
  width: 100%;

  &:not(.p-disabled) {
    border-color: $input-border;
    box-shadow: none;
    outline: none;

    &:hover {
      border-color: $mantis-green;
      box-shadow: none;
    }
    &.p-focus {
      border-color: $input-border;
      box-shadow: none;
      outline: none;
    }
  }

  .p-dropdown-label {
    font-size: $label;
    font-family: $font-family-base;
    font-weight: $normal;
    color: $black-mine-shaft;
  }

  .p-dropdown-trigger {
    color: $regent-gray;
    .p-dropdown-trigger-icon {
      font-size: $caption;

      &.pi-custom-caret-down {
        background-image: url('/assets/images/caret-down.svg');
        width: 1rem;
        height: 1rem;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .p-placeholder {
    font-size: $label;
    color: $input-placeholder;
    font-weight: $normal;
  }

  &-open {
    border-color: $mantis-green;
    border: $border-width solid $mantis-green;
    border-radius: $box-border-radius $box-border-radius 0 0;

    &:not(.p-disabled) {
      border-color: $mantis-green;
      border: $border-width solid $mantis-green;
      border-radius: $box-border-radius $box-border-radius 0 0;

      &.p-focus {
        border-color: $mantis-green;
        box-shadow: none;
        outline: none;
      }
    }

    .p-dropdown-panel {
      width: 100%;
      .p-dropdown-items-wrapper {
        border: $border-width solid $mantis-green;
        border-top: 0;
        margin-top: -2px;
      }
      .p-dropdown-items {
        .p-dropdown-item {
          font-size: $label;
          font-weight: $normal;
          color: $black-mine-shaft;
          font-family: $font-family-base;
          text-overflow: ellipsis;

          &:not(.p-disabled):hover {
            background: $alice-gray;
          }

          &.p-highlight {
            background: $alice-gray;
          }
        }
      }
    }
  }
}
