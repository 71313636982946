@import '_vars';
@import '_mixin';
@import '_typography';

.alert-header {
  @include box-primary(true);
  background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
  border-radius: $box-border-radius $box-border-radius 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $section-title-height;
  border-bottom: none;
  border-top: none;
}

.alert-data {
  border-radius: 0px 0px $box-border-radius $box-border-radius;
  position: relative;
  font-family: $font-family-base;
  .alert-row {
    @include box-primary(false, 0, 0, 0, 20px);
    border-radius: 0;
    height: $row-height;
    border-bottom: 0;
    display: flex;
    @include media(mobile) {
      min-height: $row-height;
      height: auto;
      padding: 0 0 0 $inner-spacing;
    }
    .row-name {
      @include flex-center-start;
      flex-grow: 1;

      @include media(mobile) {
        width: calc(100% - 58px);
        flex-wrap: wrap;
        padding: $inner-spacing $inner-spacing $inner-spacing 0;
      }

      span.row-title {
        font-size: $label;
        line-height: 16px;
        color: $black-mine-shaft;
        span.row-minutes {
          color: $jm-red;
        }
      }
      span.number-field {
        margin-left: 5px;
        input.minute-value {
          background-color: transparent;
          font-size: $label;
          line-height: 16px;
          color: $black-mine-shaft;
          width: auto;
          min-width: 10px;
          border: 0;
          border-bottom: $border-width dashed $jm-red;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }
      }
    }
    .setting-option {
      @include flex-center-center;
      width: 56px;
      border-left: $border-width solid $border-color;
      cursor: pointer;
      .setting-icon {
        svg path {
          @include transition(0.25s);
        }
      }

      &.disabled {
        pointer-events: none;
        .setting-icon {
          opacity: 0.25;
        }
      }
      &:hover {
        .setting-icon {
          svg path {
            fill: $mantis-green;
          }
        }
      }
    }
    .toogle {
      @include flex-center-center;
      width: $toggle-width;
      border-left: $border-width solid $border-color;
    }
    &.deselected {
      background-color: $catskill-white;
      .row-name,
      .toggle {
        pointer-events: none;
      }

      span.row-title {
        span.row-minutes {
          color: $black;
          font-weight: $medium;
        }
      }
    }
    &.loading {
      opacity: 0.4;
      pointer-events: none;
    }
    &:first-of-type {
      border-radius: $box-border-radius $box-border-radius 0px 0px;
    }
    &:last-of-type {
      border-radius: 0px 0px $box-border-radius $box-border-radius;
      border-bottom: $border-width solid $border-color;
    }
  }
  .jm-loader {
    position: absolute;
    top: 0;
    left: calc(50% - 32px);
  }
}
