.widgets-dashboard-wrapper {
  height: 100%;
  max-width: $container-max-width;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - #{$navbar-mobile-height} - #{$footer-height} - #{$main-content-padding} - #{$table-header-shadow-height});

  .dashboard-content-data {
    app-sticky-holder {
      .sticky-holder {
        z-index: 2;
        top: $main-offset;
        @include media(mobile) {
          top: $inner-spacing;
        }
        &:before {
          content: '';
          height: 24px;
          width: 100%;
          top: -24px;
          position: absolute;
          background: $alice-gray;

          @include media(tablet) {
            height: $md-spacing;
            top: -#{$md-spacing};
          }
        }
        &:after {
          content: '';
          position: absolute;
          height: $inner-spacing;
          width: 100%;
          left: 0;
          bottom: -#{$inner-spacing};
          background: linear-gradient(180deg, $alice-gray, transparent);
        }

        app-header-bar {
          @include sticky();
          top: $main-offset;
          @include media(mobile) {
            top: $inner-spacing;
          }
        }
      }
    }
  }

  .header-bar {
    min-height: 60px;
    height: auto;
    &__icon {
      padding-left: $inner-spacing;
    }
    &__title {
      padding-left: 0 !important;
    }
  }

  .period-option {
    @include box-primary;
    background-color: $catskill-white;
    display: flex;
    align-items: center;
    gap: $inner-spacing;
    height: $input-height;
    padding: 8px 15px;
    font-weight: $normal;
    font-size: $label;
    color: $black-mine-shaft;
    cursor: pointer;
    svg path {
      fill: $black-mine-shaft;
    }

    &--mobile {
      justify-content: space-between;
      margin: -$inner-spacing 0 $section-spacing 0;
      height: $button-height;
    }
  }

  .options {
    background: $base;
    border: $border-width solid $border-color;
    box-sizing: border-box;
    border-radius: $box-border-radius;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $anim-time ease-in-out;
    height: $input-height;
    width: $input-height;
    min-width: $input-height;
    .options-icon {
      svg {
        path {
          transition: $anim-time ease-in-out;
        }
      }
    }

    &:hover {
      cursor: pointer;
      border-color: $mantis-green;
      .options-icon {
        svg {
          path {
            fill: $mantis-green;
          }
        }
      }
    }
  }

  .dashboard-period-control__wrapper {
    min-width: $dashboard-period-control-wrap-min-width;
  }

  .header-bar {
    .widget-dashboard-form {
      margin-right: $inner-spacing;
      input {
        font-size: $label;
        font-weight: $bold;
        width: 100%;

        &:focus,
        &:active {
          font-size: $label;
          font-weight: $bold;
        }

        @include media(tablet up) {
          font-size: $body;
          &:focus,
          &:active {
            font-size: $body;
            font-weight: $bold;
          }
        }

        @include media(desktop up) {
          min-width: 500px;
        }
      }
      @include media(mobile) {
        width: 100%;
      }
    }

    &__info-and-navigation {
      gap: $inner-spacing;

      @include media(tablet up) {
        gap: $elements-spacing;
      }
    }

    &__title {
      font-size: $label;
      padding-left: 0;
      @include media(tablet up) {
        font-size: $body;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .not-found-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 100%;
    }
    h1.title {
      font-weight: $extra_bold;
      font-size: $heading-large;
      line-height: 43px;
      text-align: center;
      color: $black-mine-shaft;
      margin: 15px auto;
      @include min-screen($md) {
        font-size: $heading-xl;
        line-height: 53px;
      }
    }
  }
}

.widgets-dashboard-menu {
  .mat-mdc-menu-content {
    padding: $inner-spacing 0;
    min-width: 139px;
    .option {
      height: $input-height;
      padding: 0 $inner-spacing;
      display: flex;
      align-items: center;
      font-size: $caption;
      font-weight: $bold;
      color: $black-mine-shaft;

      &:hover {
        cursor: pointer;
        &:not(.disable) {
          .option-icon {
            svg path {
              fill: $mantis-green;
            }
          }
        }
      }

      &.disable {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;

        .option-icon {
          svg path {
            fill: $black-mine-shaft;
          }
        }
        span.label {
          color: $black-mine-shaft;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &-icon {
        margin-right: $inner-spacing;

        svg {
          width: $option-icon-size;
          height: $option-icon-size;

          path {
            fill: $trout;
            @include transition(0.2s);
          }
        }
      }

      &.delete {
        &:hover {
          .option-icon {
            svg path {
              fill: $cinnabar-red;
            }
          }
        }
      }
      span.label {
        font-weight: $bold;
        font-size: $caption;
        line-height: 15px;
        color: $black-mine-shaft;
      }
    }
  }
}
