@import '_vars';
@import '_mixin';

.control-center-chart-wrapper {
  @include box-primary;
  background-color: $base;
  padding: $widget-main-spacing;
  height: 100%;
  width: 100%;
  position: relative;
  @include media(mobile) {
    width: 100%;
  }
  .graph {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  app-empty-block {
    @include media(desktop up) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include media(mobile tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
