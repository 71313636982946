@import '_vars';
@import '_mixin';

$m: no-data-box;

.#{$m} {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  &__wrap {
    @include flex-center-center;
    flex-direction: column;
    text-align: center;
    .title {
      font-weight: $semi_bold;
      font-size: $title;
      line-height: 1.25;
      color: $black-mine-shaft;
      margin: 0;
      margin: 30px 0 0 0;
    }
    .description {
      font-size: $label;
      line-height: 1.5;
      color: $main-text-color;
      margin: 15px 0 30px 0;
    }

    jm-button {
      .green-button,
      .red-button {
        min-width: 220px;
        @include media(mobile) {
          margin: 0 0 60px 0;
        }
      }
    }
  }
}
