@import './controls/input-text';
@import './controls/input-number';
@import './controls/input-select';
@import './controls/upload';
@import './controls/textarea';

.quick-form {
  &__repeater {
    &.row-controls {
      display: flex;
      gap: $input-sm-spacing;
      align-items: flex-start;

      .controls {
        display: flex;
        gap: $input-md-spacing;
      }

      .form {
        &__fieldset {
          flex: 1;
        }

        &__remove {
          margin: 28px 0 0 0;
          flex: 0 0 $small-button-width;
        }
      }
    }

    &.full-width {
      width: 100%;
      quick-form-controls,
      .controls {
        width: 100%;
      }
    }
  }

  &__group {
    &.row-controls {
      .controls {
        display: flex;
      }

      .form__fieldset {
        flex: 1;
      }

      /* TODO: remove after all forms moved to quick-forms */
      .form__input:first-of-type {
        margin-right: 20px;
      }

      &.responsive {
        @include media(mobile) {
          .controls {
            flex-direction: column;
            .form__input:first-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .form__fieldset {
    margin-bottom: 10px;
  }

  .button {
    margin-top: 20px;
  }

  /* TODO: remove after all forms moved to quick-forms */
  .form__input:first-of-type {
    margin-right: 0;
  }

  &--machine-categories {
    margin-bottom: 0;
  }
}
