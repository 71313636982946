@import '_vars';
@import '_mixin';

.mat-mdc-tab-group {
  max-width: 1440px !important;
  width: 100% !important;
  margin: 20px auto 0;

  .mat-mdc-tab-header {
    @include box-primary(false, 0, 0, 0, 0);
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: $catskill-white;
    @include media(mobile) {
      border: none;
    }
    .mat-mdc-tab-labels {
      height: 60px;
      border-bottom: $border-width solid $border-color;
      .mat-mdc-tab {
        font-family: $font-family-base;
        background: $catskill-white;
        box-sizing: border-box;
        height: 60px;
        opacity: 1;
        box-shadow: inset 0 -1px 0 0 $border-color;
        border-right: $border-width solid $border-color;
        min-width: 160px;

        @include media(mobile) {
          height: 60px;
          padding: 0 12px;
        }

        .mdc-tab__text-label {
          font-size: $label;
          line-height: 1.25;
          color: $main-text-color;
          transition: 0.2s color ease-in-out;

          @include media(desktop up) {
            font-size: $body;
          }
        }

        .mdc-tab-indicator {
          display: none;
        }

        .mat-ripple-element {
          opacity: 1 !important;
          background-color: transparent;
        }

        .mdc-tab__ripple::before {
          background-color: transparent !important;
        }

        &:hover {
          .mdc-tab__text-label {
            color: $nevada-gray;
          }
        }

        &:first-of-type {
          @include media(mobile) {
            border-left: $border-width solid $border-color;
          }
        }

        &.invalid {
          background-color: $light-red;
          .mdc-tab__text-label {
            color: $jm-red !important;
          }
        }

        &.mdc-tab--active {
          background: $base !important;
          border-top: 4px solid $mantis-green;
          box-sizing: border-box;
          box-shadow: 0px -2px 0px $mantis-green;
          border-right: $border-width solid $border-color;
          box-shadow: inset 0 -1px 0 0 transparent;
          .mdc-tab__text-label {
            color: $black-mine-shaft;
            font-weight: $bold;
          }
        }

        &:first-child {
          &.mdc-tab--active {
            @include media(tablet up) {
              border-left: none;
            }
          }
        }
      }
    }
  }
  &.article-template-group {
    max-width: 100%;
    margin: 0 auto;
    .mat-mdc-tab-header {
      border: 0;
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          flex: 0 1 33.33%;
          min-width: initial;

          @include media(mobile) {
            flex: 1;
          }
          &:first-of-type {
            border-left: 0;
          }
        }
      }
    }
  }
  &.performance-dashboard-group {
    max-width: 100% !important;
    margin: 0 auto;
    .mat-mdc-tab-header {
      border: 0;
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          flex: 0 1 25%;
          min-width: initial;
          &:first-of-type {
            border-right: $border-width solid $border-color;
          }
          &:first-child {
            &.mat-mdc-tab-active {
              @include media(tablet up) {
                border-left: $border-width solid $border-color;
              }
            }
          }
        }
      }
    }
  }
  &.tabs-2 {
    max-width: 100%;
    margin: 0 auto;
    .mat-mdc-tab-header {
      border: 0;
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          flex: 0 1 50%;
          min-width: initial;
          &:first-of-type {
            border-left: 0;
          }
        }
      }
    }
  }
  &.tutorial-tabs {
    max-width: 100%;
    margin: 0 auto;
    .mat-mdc-tab-header {
      border: 0;
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          @include media(tablet up) {
            flex: 1;
            min-width: initial;
          }
          &:first-of-type {
            border-left: $border-width solid $border-color;
          }
          &:last-of-type {
            border-right: $border-width solid $border-color;
          }
        }
      }
    }
  }
  &.week-tabs {
    max-width: 100%;
    margin: 0 auto;
    .mat-mdc-tab-header {
      border: 0;
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          flex: 0 1 14.28%;
          min-width: initial;
          &:first-of-type {
            border-left: 0;
          }
        }
      }
    }
  }
}
