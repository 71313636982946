@import '_vars';
@import '_mixin';

.machine-data-wrapper {
  width: 100%;
  .tab-group-wrapper {
    display: flex;
    justify-content: space-between;
    background: $base;
    border-top: $border-width solid $border-color;
    border-left: $border-width solid $border-color;
    border-right: $border-width solid $border-color;
    box-shadow: inset 0 -1px 0 0 $border-color;
    box-sizing: border-box;
    border-radius: $box-border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: $catskill-white;
    .mat-mdc-tab-label-container {
      flex-grow: 0 !important;
    }
    .mat-mdc-tab-group {
      width: auto !important;
      margin: 0 0 0 -1px !important;
      @include media(mobile) {
        max-width: unset;
      }
      .mat-mdc-tab-header {
        border-radius: 0;
        border-top: 0;
        .mat-mdc-tab-labels {
          @include media(mobile) {
            height: $tab-height;
          }
          .mat-mdc-tab {
            @include media(mobile) {
              height: $tab-height;
            }
          }
        }
      }
    }
  }
  .order-data-wrapper {
    @include box-primary(true);
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.action-buttons-machine-orders {
  display: flex;
  align-items: center;
  padding-right: $inner-spacing;
  jm-button:first-of-type {
    margin-right: $inner-spacing;
  }
  &.mobile-version {
    display: none;
    @include media(mobile) {
      display: flex;
      margin-bottom: $section-spacing;
    }
  }
  &.desktop-version {
    @include media(mobile) {
      display: none;
    }
  }
}
