@import '_vars';
@import '_mixin';

$m: order-form;

.#{$m} {
  display: block;
  height: 100%;

  @include media(tablet up) {
    display: flex;
  }
  &__box {
    height: auto;
    width: 100;
    margin-bottom: 20px;
    @include media(tablet up) {
      height: 100%;
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 0;
      overflow: auto;
    }
    .order-info {
      width: 100%;
      .quick-form {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .form__fieldset {
          max-width: calc(50% - 10px);
          width: 100%;
          flex: none;
          @include media(mobile tablet) {
            max-width: 100%;
          }
        }
      }
    }
    .order-box {
      @include box-primary;
      margin-bottom: 20px;
      overflow: hidden;
      .intro {
        @include box-primary;
        background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
        border: 0;
        border-bottom: 1px solid $border-color;
        border-radius: 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .title {
          color: $black-mine-shaft;
          margin-bottom: 0;
          font-size: $font-size;
          font-weight: $bold;
          display: flex;
          align-items: center;
          .info-icon {
            margin-left: 15px;
            path {
              fill: $cadet-gray;
            }
            &:hover {
              cursor: pointer;
              path {
                fill: $mantis-green;
              }
            }
          }
          .machine-icon {
            margin-right: $inner-spacing;
          }
        }
        .tag {
          font-size: $table-tag;
          font-weight: $bold;
          padding: 4px 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 1;
          margin-left: 15px;
          @include media(tablet up) {
            text-transform: uppercase;
            background: $main-text-color;
            @include background-opacity($main-text-color, 0.1);
            border-radius: $box-border-radius;
          }

          @include media(mobile) {
            border-radius: $box-border-radius;
            padding: $table-cell-padding;
          }
          &.danger {
            color: $jm-red;
            background-color: $white;
            border: $border-width solid $jm-red;
          }

          &.success {
            color: $mantis-green;
            background-color: $white;
            border: $border-width solid $mantis-green;
          }
          &.warning {
            color: $yellow-1;
            background-color: $white;
            border: $border-width solid $yellow-1;
          }

          &.disable {
            color: $silver-chalice;
            background-color: $white;
            border: $border-width solid $silver-chalice;
          }
        }
      }
      .data-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: $elements-spacing;
        margin-bottom: 20px;
        padding: 20px 20px 0;

        @include media(mobile) {
          flex-direction: column;
        }
      }
      .quick-form {
        padding: 20px;
        .production-unit {
          display: flex;
          gap: 20px;
        }
      }
      .note {
        margin: 0 20px 20px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &.order-configuration-box {
        height: 100%;
        .form-box {
          .form-half-box {
            width: calc(50% - 10px);
            margin-right: 20px;
            label {
              font-weight: $bold;
              font-size: $caption;
              line-height: 1.5;
              color: $black-mine-shaft;
              text-overflow: ellipsis;
              overflow: hidden;
              height: 15px;
              white-space: nowrap;
            }
            &.quantity-box {
              ::ng-deep label {
                overflow: visible;
                display: flex;
                align-items: center;
              }
            }
            &:last-of-type {
              margin-right: 0px;
            }
          }
          &.two-columns-form-box {
            display: flex;
            .form-half-box {
              @include media(mobile) {
                width: 100% !important;
                height: 80px;
                margin-bottom: 20px;
              }
              &:last-of-type {
                @include media(mobile) {
                  margin-bottom: 0;
                }
              }
            }
          }
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
    &.article-box {
      @include box-primary;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
      &.invalid {
        border-color: $jm-red;
        margin-bottom: 0;
      }
    }
    &:last-of-type {
      margin-right: 0px;
      @include media(mobile) {
        margin-bottom: 0;
      }
    }
  }
  p.invalid {
    font-size: $caption;
    line-height: 21px;
    color: $jm-red;
    margin: 3px 0 0 0;
  }
}
