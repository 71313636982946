// Default toggle arrow styling
$m: toggle-arrow;

.#{$m} {
  position: absolute;
  right: 15px;
  top: calc(50% - 10px);
  &.active-toggle-arrow-icon {
    transform: rotate(180deg);
    svg path {
      fill: $black-mine-shaft;
    }
  }
}
