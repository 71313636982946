@import '_vars';
@import '_mixin';

.app-modal {
  padding: $modal-spacing-md;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  max-height: 98vh;

  @include media(mobile) {
    height: 100vh;
    padding: $section-spacing;
  }

  &__header {
    position: relative;
    padding-bottom: $section-spacing;

    @include media(tablet up) {
      padding-bottom: $modal-spacing-md;
      padding: 0 30px $modal-spacing-md 0;
    }

    .title {
      font-size: $body;
      font-weight: $bold;
      color: $black-mine-shaft;
      line-height: 1.2;
      flex-grow: 1;
      margin: 0;

      @include media(tablet up) {
        font-size: $heading;
      }
    }

    .close-icon {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: -$elements-spacing;
      top: -$elements-spacing;

      svg path {
        @include transition(0.2s);
      }

      &:hover {
        cursor: pointer;
        svg path {
          fill: $mantis-green;
        }
      }

      @include media(mobile) {
        top: 0;
        right: 0;
      }
    }
  }

  &__main {
    flex-grow: 1;
  }

  &__footer {
    margin-top: $modal-spacing;
    .jm-button:not(:first-child) {
      margin-left: $inner-spacing;
    }

    .sb-button {
      @include media(mobile) {
        flex: 1;
      }
    }

    @include media(mobile) {
      display: flex;
    }
  }

  &--create-dashboard {
    @include media(mobile) {
      height: auto;
    }
  }
}
