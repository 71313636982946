@import '_vars';

div.dashboard-data-wrapper {
  width: 100%;
  margin-top: $section-spacing;
  height: 100%;
  position: relative;

  @include min-screen($md) {
    overflow-y: auto;
  }

  @include max-screen($lg - 1) {
    height: 100%;
  }

  @include max-screen($md - 1) {
    height: auto !important;
    overflow: auto;
    padding: 0 !important;
  }

  .no-data-wrapper {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    margin: 0 auto;
    border-bottom: 1px solid $border-color;
    text-align: center;

    @include min-screen($md) {
      padding: 30px 0 40px 0;
    }

    p.message {
      font-size: $body;
      line-height: 26px;
      font-weight: 600;
      margin-top: 25px;
      color: $main-text-color;

      @include min-screen($md) {
        margin-top: 50px;
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  .how-to-add {
    margin: 40px auto;
    @include max-screen($md - 1) {
      margin: 40px;
    }

    .title {
      font-weight: $bold;
      font-size: 21px;
      line-height: 26px;
      color: $black-mine-shaft;
      text-align: center;
      margin-bottom: 15px;
    }
    .message {
      font-size: 14px;
      line-height: 21px;
      color: $black-mine-shaft;
      text-align: center;
      .important {
        color: $jm-red;
      }
    }
  }

  &.full-width-dashboard-data-wrapper {
    padding: 0;
  }
  &.mobile-view-dashboard-data-wrapper {
    height: calc(100% - 157px);
    margin-top: 5px;
    padding: 0;
    app-orders {
      .orders-wrapper {
        width: 100%;
      }
    }
  }
  &.chart-route-mobile-view {
    height: 100%;
    width: 100%;
  }
  &.machine-details-data-wrapper {
    height: calc(100% - 154px);
  }
}
