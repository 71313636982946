@import '_vars';
@import '_mixin';

.quick-form {
  .form__fieldset {
    .quick-dropdown {
      padding-top: 4px;

      label {
        font-weight: $bold;
        font-size: $caption;
        line-height: 18px;
        color: $black-mine-shaft;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        white-space: nowrap;
        height: 15px;

        &.label--info {
          display: flex;
          align-items: center;

          .info-icon {
            margin-left: 10px;
            cursor: pointer;
            &:hover svg path {
              fill: $mantis-green;
            }
          }
        }
      }
      ::ng-deep .ng-select {
        width: 100% !important;
        max-width: 100% !important;
        .ng-select-container {
          height: $input-height !important;
          max-height: $input-height !important;
          min-height: $input-height !important;
          border: 1px solid $input-border;
          box-sizing: border-box;
          border-radius: 5px;
          width: 100%;
          background: $catskill-white;
          @include transition(0.2s);
          .ng-value-container {
            padding-left: 15px;
            .ng-value-label {
              font-size: $label;
              line-height: 18px;
              color: $black-mine-shaft;
            }
            .ng-placeholder {
              font-size: $label;
              line-height: 18px;
              color: #8d979e;
              display: block;
            }
            .ng-input > input {
              font-size: $label;
              line-height: 28px;
              color: $black-mine-shaft;
              margin-top: 0;
              padding-left: 3px;
            }
            .ng-value {
              margin-top: 0px;
              display: flex;
              align-items: center;
            }
          }
          .ng-clear-wrapper {
            display: none;
          }
          &:hover {
            border: 1px solid $feijoa-green;
          }
        }
        .ng-dropdown-panel {
          .ng-dropdown-panel-items {
            .ng-option {
              height: $input-height;
              max-height: $input-height;
              margin: 0px !important;
              display: flex !important;
              align-items: center;
              padding-left: 15px;
              span.ng-option-label {
                font-size: $label;
                line-height: 18px;
                color: $black-mine-shaft;
              }
              &.ng-option-marked {
                background-color: $alice-gray;
              }
              &.ng-option-selected {
                background-color: $alice-gray;
              }
            }
          }
          .scroll-host {
            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $scrollbar-color;
              outline: 1px solid $scrollbar-color;
            }
          }
          &.ng-select-bottom {
            margin-top: 0;
            border: 1px solid $feijoa-green !important;
            border-top: 0px !important;
          }
        }
        .ng-arrow-wrapper .ng-arrow {
          border-color: $trout transparent transparent !important;
          border-width: 6px 4px 3px !important;
        }
        .ng-clear-wrapper:hover .ng-clear {
          color: $mantis-green;
        }
        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
          box-shadow: none;
          border: 1px solid $feijoa-green;
        }
        &.ng-select-opened {
          .ng-select-container {
            border: 1px solid $feijoa-green !important;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.06) !important;
            border-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
        &.ng-select-disabled {
          .ng-select-container {
            border: 1px solid $input-border-disabled;
            box-shadow: none;
            cursor: not-allowed;
            background: $alice-gray;
            .ng-placeholder {
              color: $button-disabled-text;
            }
            .ng-value-container .ng-input {
              cursor: not-allowed;
            }
            .ng-value-container .ng-value-label {
              color: $button-disabled-text !important;
            }
            .ng-arrow-wrapper .ng-arrow {
              border-color: $border-color transparent transparent !important;
            }
          }
        }
        &.invalid {
          .ng-select-container {
            border-color: $jm-red !important;
          }
          &.ng-select-opened .ng-select-container {
            border-color: $feijoa-green !important;
          }
          &.ng-valid .ng-value-container .ng-placeholder {
            display: block !important;
          }
        }
        &.ng-select-filtered {
          .ng-select-container {
            .ng-value-container .ng-placeholder {
              display: none !important;
            }
          }
        }
        &.ng-touched,
        &.ng-untouched,
        &.ng-valid {
          .ng-select-container.ng-has-value {
            .ng-value-container .ng-placeholder {
              display: none !important;
            }
            .ng-clear-wrapper {
              display: block;
              color: $jm-red;
              @include transition(0.2s);
            }
          }
        }
        &.ng-select-multiple {
          height: auto !important;
          .ng-select-container {
            height: auto !important;
            max-height: unset !important;
            .ng-value-container {
              max-width: calc(100% - 25px);
              .ng-placeholder {
                top: 14px;
                left: 20px;
              }
              .ng-value {
                background-color: $base;
                border: 1px solid $border-color;
                box-sizing: border-box;
                border-radius: 5px;
                span.ng-value-icon {
                  border: 0 !important;
                  .close-icon {
                    svg {
                      width: 9px;
                    }
                  }
                  &:hover {
                    background-color: transparent;
                    .close-icon {
                      svg path {
                        fill: $mantis-green;
                      }
                    }
                  }
                }
              }
            }
            .ng-arrow-wrapper {
              position: absolute;
              right: 0;
              top: 10px;
            }
            &.ng-has-value {
              .ng-placeholder {
                top: 0;
              }
            }
          }
          .ng-dropdown-panel {
            margin-top: 0px;
          }
        }
        &.dropdown-panel-126 .ng-dropdown-panel .scroll-host {
          max-height: 126px;
        }
      }
    }
  }
}
