//DEFAULT
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'material-design/_mat-form-select';
@import 'material-design/_mat-slide-toogle';
@import 'material-design/_mat-form-checkbox';
@import 'material-design/_mat-radio-button';
@import 'material-design/notification';
@import 'dropdowns/ng-select.theme';

//  VARIABLES AND MIXINS, LAYOUT SETTINGS
@import '_vars';
@import '_mixin';
@import '_typography';
@import '_layout';
@import '_animations';

//general styles
@import 'general-styles';

//emnbedded
@import 'embedded';

//CUSTOM MODULES
@import 'modules/components/main-holder.scss';
@import 'modules/components/helpers.scss';
@import 'modules/components/cards.scss';
@import 'modules/components/jakob-muller-table.scss';
@import 'modules/components/scrollbar.scss';
@import 'modules/components/forms.scss';
@import 'modules/components/toggle-arrow.scss';
@import 'modules/components/calendar.scss';
@import 'modules/components/tag.scss';
@import 'modules/components/mat-tab.scss';
@import 'modules/components/jm-modal.scss';
@import 'modules/components/component-details-wrapper.scss';
@import 'modules/components/templates-box.scss';

// LIBRARY MODULE GENERAL STYLES
@import 'modules/library/library';
@import 'modules/library/article-details';

// ADMIN MODULE STYLES
@import 'modules/admin/admin-form';

// DASHBOARD MODULE
@import 'modules/dashboard/map';
@import 'modules/dashboard/module-sidebar';
@import 'modules/dashboard/dashboard-navigation';
@import 'modules/dashboard/dashboard-location-data';
@import 'modules/dashboard/charts';
@import 'modules/dashboard/chart-component';
@import 'modules/dashboard/orders';
@import 'modules/dashboard/machine-orders.scss';

// NOTIFICATION MODULE
@import 'modules/notifications/notifications-configuration';

// ALERTS
@import 'modules/_alert';

// PAGES
@import 'modules/user-profile';

// UI
@import 'ui/_common';
@import 'ui/_chips';
@import 'ui/_panel';
@import 'ui/_no-data-box';

//FORMS
@import 'ui/quick-forms/quick-forms';

//TABLE
@import 'ui/jm-table/jm-table';

// ORDER FORM
@import 'modules/order/form';

//COMPONENTS
@import 'ui/p-dropdown/p-dropdown';
@import 'ui/p-multiselect/p-multiselect';
@import 'ui/p-datepicker/p-datepicker';

// WIDGETS
@import 'ui/_dashboard';
@import 'modules/gridster/_gridster';
@import 'ui/_widgets';

//POPUP MENUS
@import 'ui/_impersonation-menu';
@import 'ui/_app-modal';

// PROGRESS BAR
@import 'ui/_progress-bar';

$custom-typography: mat.define-typography-config(
  $font-family: $font-family-base !important,
);

$custom-theme: mat.define-light-theme(
  (
    typography: $custom-typography,
  )
);

@include mat.core();
@include mat.all-component-themes($custom-theme);
