.quick-form {
  .form__fieldset {
    .input-wrapper {
      &.number {
        padding-top: 4px;
      }

      &.half-width {
        width: 50%;
      }
    }

    .number-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .description {
        font-size: 12px;
        line-height: 15px;
        color: $black-mine-shaft;
        margin-bottom: 8px;
      }
    }

    input[type='number'] {
      -moz-appearance: textfield;
      &:hover,
      &:focus {
        -moz-appearance: number-input;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }
}
