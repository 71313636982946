@import '_vars';
@import '_mixin';

// impersonation-menu
$m: impersonation-menu;

.#{$m} {
  margin-top: $xs-spacing;
  width: $impersonation-menu-width;
  position: relative;
  background: $jm-red !important;

  &__holder {
    padding: $section-spacing;
    background: $base;
    border-radius: $impersonation-menu-decoration-radius $impersonation-menu-decoration-radius 0 0;
    margin-top: $impersonation-menu-decoration-height;
  }

  &__title {
    color: $black-mine-shaft;
    line-height: 1.25;
    .current {
      font-size: $caption;
    }

    .user {
      font-size: $body;
      font-weight: $bold;
    }
  }

  &__user-link {
    color: $jm-red;
    font-size: $label;
    font-weight: $bold;
    text-decoration: none;
    transition: $anim-time all ease-in-out;
    display: flex;
    gap: $inner-spacing;
    align-items: center;

    .link-icon {
      margin-top: 3px;
      svg {
        path {
          transition: $anim-time fill ease-in-out;
        }
      }
    }

    &:hover {
      color: $mantis-green;
      .link-icon {
        svg {
          path {
            fill: $mantis-green;
          }
        }
      }
    }
  }
}
