@import '_vars';
@import '_mixin';
@import '_typography';

.mat-mdc-radio-group {
  display: flex;

  .mat-mdc-radio-button {
    margin-right: 30px;
    display: flex;
    align-items: center;

    .mdc-label {
      font-size: 12px;
      line-height: 18px;
      color: $black-mine-shaft;
      padding: 0px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .mat-mdc-radio-button {
    .mdc-radio {
      & .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: $mantis-green !important;
      }

      & .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
      &.mat-mdc-radio-checked .mdc-radio__background::before {
        background-color: $mantis-green !important;
        border-color: $mantis-green !important;
      }

      & .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
        border: $border-width solid $border-color;
        background-color: $catskill-white;
      }
    }

    .mat-radio-ripple {
      display: none;
    }
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: transparent !important;
}

.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
  opacity: 0 !important;
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  opacity: 0 !important;
  background-color: transparent !important;
}
