@import '_mixin';

.chart.speed,
.chart.stops,
.chart.efficiency {
  #chartsContainer {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-right: 20px;
    #chartsWrapper {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      #percentVerticalScrollBarChart,
      #verticalScrollBarChart,
      #stackedVerticalScrollBarChart {
        width: 100%;
        margin: 0 auto;
        height: 100%;
        background: #fff;
        padding-bottom: 20px;
      }

      .customChart {
        svg {
          font: 10px sans-serif;

          g {
            text {
              font-family: $font-family-base;
            }
            &.y-axis {
              transform: translate(-9px, 0px);
            }
          }
        }
        .axis text {
          font-size: $caption_small;
        }
        .axis path,
        .axis line {
          fill: none;
          stroke: #000;
          shape-rendering: crispEdges;
        }
        .axis line,
        .axis .domain {
          stroke-width: 2px;
          stroke: #d8d8d8;
        }
        .y-axis text {
          font-weight: $bold;
          font-size: $caption;
        }
        .x-axis g.tick:first-of-type,
        .x-axis g.tick:last-of-type {
          display: none;
        }

        .x-axis-title {
          text-anchor: middle;
          font-size: $caption;
          font-weight: $bold;
          fill: #595959;
        }
        .chartWrapper {
          //overflow-y: scroll;
          overflow-y: auto;
          overflow-x: hidden;
          margin: 0 auto;
        }
        .xAxisWrapper {
          margin: 0 auto;
          padding-top: 6px;
        }

        /*
        *  STYLE 1 - https://codepen.io/devstreak/pen/dMYgeO
        */

        .chartWrappert::-webkit-scrollbar-track {
          /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
          border-radius: 10px;
          background-color: #fff;
        }

        .chartWrapper::-webkit-scrollbar {
          width: 10px;
          background-color: #fff;
        }

        .chartWrapper::-webkit-scrollbar-thumb {
          border-radius: 10px;
          /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
          background-color: #d8d8d8;
        }
      }

      #verticalScrollBarChart {
        .bar {
          opacity: 0.75;
        }
        .outerCircle {
          fill: white;
          stroke: white;
          stroke-width: 1;
        }

        .innerCircle {
          fill: transparent;
          stroke: black;
          stroke-width: 1;
          max-width: 100px;
        }

        .label {
          font-size: $body;
          font-weight: 600;
          text-anchor: middle;
          color: white;
        }
      }

      .d3-hide {
        display: none !important;
      }
    }
    &.hide-chart {
      opacity: 0;
    }
    &.show-chart {
      opacity: 1 !important;
    }
  }
}

.no-data-available {
  @include flex-center-center;
  height: 100%;
}

.line-chart,
.stacked-bar-chart {
  font-size: 0.8rem;

  path {
    stroke: hsl(0, 0%, 58%);
    stroke-width: 3;
    fill: none;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #cacaca;
    stroke-width: 3;
    shape-rendering: crispEdges;
  }

  .tick text,
  .axis-label {
    font-weight: 600;
  }

  .axis-label {
    fill: #545454;
    font-size: $body;
  }

  &.graph_container {
    display: block;
    width: 100%;
    height: 100%;
  }
}

#lineChart {
  height: 400px;
  width: 700px;

  @include media(mobile) {
    height: 250px;
    width: 300px;
  }
}
