.widget {
  border: $border-width solid $border-color;
  border-radius: $box-border-radius;
  background: $base;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    background: linear-gradient(180deg, $base 0%, #f7f9fb 100%);
    height: $box-header-height;
    min-height: $box-header-height;
    padding: 0 10px 0 $section-spacing;
    display: flex;
    align-items: center;
    border-radius: $box-border-radius $box-border-radius 0 0;
    border-bottom: $border-width solid $border-color;

    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    flex: 1;
    min-height: 0;
  }

  &__main {
    background: $base;
    padding: $section-spacing;
    overflow: auto;
    border-radius: $box-border-radius;
    flex-grow: 1;
    position: relative;
    height: 100%;
  }
}
