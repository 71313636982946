.app-router.embedded {
  .main-holder--admin,
  .main-holder--library {
    .main-holder__content .main-content .sticky-holder {
      @include media(desktop up) {
        top: $main-offset-embedded;
      }
    }
  }

  .search-box--sticky {
    @include media(desktop up) {
      top: $boxes-offset-embedded;
    }
  }

  .sticky-header--after-header-bar:not(.overflow-table) {
    .p-datatable {
      .p-datatable-wrapper {
        .p-datatable-table {
          .p-datatable-thead {
            @include media(desktop up) {
              top: calc(#{$search-bar-height} + #{$section-title-height} + (#{$section-spacing} * 2) + #{$inner-spacing});
            }
          }
        }
      }
    }
  }

  .main-holder--admin {
    .main-holder__content {
      .module-sidebar--admin {
        @include media(desktop up) {
          top: $main-offset-embedded;
        }
      }
    }
  }

  .widgets-dashboard-wrapper {
    .dashboard-content-data {
      app-sticky-holder {
        .sticky-holder {
          top: $main-offset-embedded;
        }
      }
    }
  }
}
