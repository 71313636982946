.p-inputwrapper {
  .p-multiselect {
    font-size: $label;
    font-family: $font-family-base;
    margin: $filter-box-spacing 0;
    background: $catskill-white;
    border: $border-width solid $input-border;
    border-radius: $box-border-radius;
    padding: 1.5px 0 1.5px 7px;
    transition: border-color 0.2s ease-in-out;
    box-shadow: none;
    outline: none;
    width: 100%;

    &:not(.p-disabled) {
      border-color: $input-border;
      box-shadow: none;
      outline: none;

      &:hover {
        border-color: $mantis-green;
        box-shadow: none;
      }
      &.p-focus {
        border-color: $input-border;
        box-shadow: none;
        outline: none;
      }
    }

    .p-multiselect-label {
      font-size: $label;
      font-family: $font-family-base;
      font-weight: $normal;
      color: $black-mine-shaft;
      max-width: 233px;
    }

    .p-multiselect-trigger {
      color: $regent-gray;
      .p-multiselect-trigger-icon {
        font-size: $caption;

        &.pi-custom-caret-down {
          background-image: url('/assets/images/caret-down.svg');
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .p-placeholder {
      font-size: $label;
      color: $input-placeholder;
      font-weight: $normal;
    }
  }
  &-focus {
    .p-multiselect {
      border-color: $mantis-green;
      border: $border-width solid $mantis-green;
      border-radius: $box-border-radius $box-border-radius 0 0;

      &:not(.p-disabled) {
        border-color: $mantis-green;
        border: $border-width solid $mantis-green;
        border-radius: $box-border-radius $box-border-radius 0 0;

        &.p-focus {
          border-color: $mantis-green;
          box-shadow: none;
          outline: none;
        }
      }

      .p-multiselect-panel {
        width: 100%;
        .p-multiselect-items-wrapper {
          border: $border-width solid $mantis-green;
          border-top: 0;
          margin-top: -2px;
        }
        .p-multiselect-items {
          padding: 0;
          .p-multiselect-item {
            font-size: $label;
            font-weight: $normal;
            color: $black-mine-shaft;
            font-family: $font-family-base;
            text-overflow: ellipsis;
            .p-checkbox-box {
              border-radius: 5px;
              .p-checkbox-icon {
                color: $base;
                font-weight: $bold;
              }
              &.p-highlight {
                background-color: $mantis-green;
                border-color: $mantis-green;
              }
            }

            &:not(.p-disabled):hover {
              background: $alice-gray;
            }

            &.p-highlight {
              background: $alice-gray;
            }
          }
        }
      }
    }
  }
}
