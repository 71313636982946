@import '_vars';
@import '_mixin';

.admin-form {
  @include box-primary(false, 40px, 20px, 40px, 20px);
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  overflow: auto;

  @include media(mobile) {
    height: auto !important;
  }

  .form-half {
    flex: 1;
    margin-right: 40px;
    @include screen($md, $desktop - 1) {
      margin-right: 20px;
    }

    .date-pick-wrapper {
      display: flex;
      .checkbox-wrapper {
        height: 80px;
        width: 100%;
        padding-top: 32px;
        padding-left: 15px;
      }
    }
    &.loading {
      opacity: 0.5;
      pointer-events: none;
    }
    &:last-of-type {
      margin-right: 0px;
    }
  }

  &.auto-height {
    height: auto;
  }
  &.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
