// Default Jakob Muller table styling
$m: jakob-muller-table;

.#{$m} {
  .table-row {
    box-sizing: border-box;
    height: $row-height;
    display: flex;
    align-items: center;

    &.table-header-row {
      padding: 0 30px 0 40px;
      border-radius: 0px 5px 0px 0px;
      background: linear-gradient(180deg, $base 0%, #f7f9fb 100%);
      border-bottom: 1px solid $border-color;
      @include max-screen($md - 1) {
        padding: 0 15px;
      }
      .table-columns {
        color: $black-mine-shaft;
        font-size: $caption;
        font-weight: $bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        @include max-screen($md - 1) {
          text-align: center;
        }
        .sort-icon-wrapper {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          i.sort-icon {
            color: $black-mine-shaft;
            opacity: 0.5;
            font-size: $label;
          }
          .sort-icon-arrow {
            &.asc {
              transform: rotate(180deg);
              margin-bottom: 3px;
            }
            &.active-sort {
              svg path {
                fill: $trout;
                fill-opacity: 1;
              }
            }
            &:hover {
              svg path {
                fill: $mantis-green;
              }
            }
          }
        }
        .icon {
          margin-left: $inner-spacing;
        }

        &.sortable {
          padding: 0 15px 0 0;
          justify-content: flex-start;
        }
        &.setting-column {
          justify-content: flex-end;
          padding-right: 0;
        }
        &.active {
          .sort-icon-wrapper i.sort-icon {
            color: $jm-red;
            opacity: 1;
          }
        }
        &:hover {
          .sort-icon-wrapper i.sort-icon {
            color: $mantis-green;
            opacity: 1;
          }
        }
      }
    }

    &.table-data-row {
      border: 1px solid $border-color;
      background: $catskill-white;
      border-radius: 5px;
      padding: 0 5px 0 20px;
      margin-bottom: 10px;
      cursor: pointer;
      @include transition(0.25s);
      @include min-screen($desktop) {
        padding: 0 0 0 20px;
      }
      &:hover {
        .bolded-column {
          span {
            color: $mantis-green;
          }
        }
      }

      @include max-screen($md - 1) {
        position: relative;
        padding: 10px 0 0 10px;
        .action-button {
          @include box-primary;
          @include flex-center-center;
          cursor: pointer;
          width: 36px;
          height: 36px;
          position: absolute;
          right: 5px;
          top: 5px;
          svg {
            path {
              fill: $black-mine-shaft;
            }
          }
          &:hover {
            border-color: $mantis-green;
            svg path {
              fill: $mantis-green;
            }
          }
        }
        .machine-created {
          position: absolute;
          right: 50px;
          top: 15px;
        }
        .order-mobile-wrapper {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
      &.active-table-data-row {
        min-height: $row-height;
        height: auto;
        border: 1px solid $mantis-green;
      }
      .mobile-details {
        width: 100%;
        padding: 10px 0;
        .mobile-data {
          font-size: 11px;
          line-height: 20px;
          display: flex;
          font-weight: $semi_bold;
          .mobile-detail-title {
            width: 120px;
            display: block;
            font-weight: $normal;
            font-size: $caption;

            @include max-screen(360px) {
              width: 80px;
            }
          }
        }
        &--many_items {
          .mobile-data {
            justify-content: flex-start;
            .mobile-detail-title {
              flex: 0 1 30%;
            }
            .multiple-values {
              flex: 0 1 65%;
              padding-right: 5px;
            }
          }
        }
      }

      .table-columns {
        white-space: nowrap;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button-more-options {
          @include box-primary;
          @include flex-center-center;
          height: $more-options-btn-sizing;
          width: $more-options-btn-sizing;
          min-width: $more-options-btn-sizing;
          @include transition(0.2s);

          svg path {
            @include transition(0.2s);
          }

          .option-icon {
            display: flex;
          }
          &.disabled {
            opacity: 0.5;
          }
          &.dequeueable {
            margin-left: $elements-spacing;
          }

          &:hover {
            border: 1px solid $mantis-green;
          }

          &:hover:not(.disabled) .option-icon {
            svg path {
              fill: $mantis-green;
            }
          }
        }
        &.multiple-values {
          display: block;
          white-space: pre-line;
          padding: 3px 0;
        }
      }
      &.no-hover {
        cursor: auto;
      }
      &:hover:not(.no-hover) {
        @include min-screen($md) {
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.04);
          background: $base;
          border: 1px solid $mantis-green;
        }
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    &.footer-row {
      background: $alice-gray !important;
      .table-columns {
        font-weight: $bold;
        font-size: $label;
        line-height: 18px;
      }
    }
    .table-columns {
      line-height: 18px;
      color: $black-mine-shaft;
      font-size: $caption;
      @include min-screen($lg) {
        font-size: $label;
      }

      &.bolded-column {
        font-weight: $bold;
        span {
          display: flex;
          gap: 10px;
          @include transition(0.25s);
        }
      }
      &.text-box {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      &.jm-column-1 {
        width: calc(100% / 1);
      }
      &.jm-column-2 {
        width: calc(100% / 2);
      }
      &.jm-column-3 {
        width: calc(100% / 3);
      }
      &.jm-column-4 {
        width: calc(100% / 4);
      }
      &.jm-column-5 {
        width: calc(100% / 5);
      }
      &.jm-column-6 {
        width: calc(100% / 6);
      }
      &.jm-column-7 {
        width: calc(100% / 7);
      }
      &.jm-column-8 {
        width: calc(100% / 8);
      }
      &.jm-column-9 {
        width: calc(100% / 9);
      }
      &.jm-column-10 {
        width: calc(100% / 10);
      }
      &.jm-column-11 {
        width: calc(100% / 11);
      }
      &.jm-column-12 {
        width: calc(100% / 12);
      }
      &.jm-column-13 {
        width: calc(100% / 13);
      }
      &.jm-column-14 {
        width: calc(100% / 14);
      }
      &.jm-column-15 {
        width: calc(100% / 15);
      }
      &.jm-column-16 {
        width: calc(100% / 16);
      }
      &.jm-column-17 {
        width: calc(100% / 17);
      }
      &.jm-column-18 {
        width: calc(100% / 18);
      }
      &.jm-column-19 {
        width: calc(100% / 19);
      }
      &.jm-column-20 {
        width: calc(100% / 20);
      }

      @include max-screen($md - 1) {
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;
        width: calc(100% - 70px) !important;
      }
    }
  }

  .table-data {
    padding: 20px;
    overflow-y: auto;
    @include max-screen($md - 1) {
      padding: 15px 10px;
      height: auto;
    }
  }

  .no-data {
    text-align: center;
    margin: 30px 0;
    font-weight: $bold;
    font-size: rem(12px);
    line-height: 15px;
    color: $black-mine-shaft;
  }

  .jm-loader {
    height: 100%;
    align-items: center;
  }

  &--orders {
    .table-data {
      .table-row {
        &.table-data-row {
          @include min-screen($desktop) {
            padding: 0 4px 0 20px;
          }
        }
        .table-columns {
          span {
            text-overflow: ellipsis;
            max-width: 100%;
            /* Needed to make it work */
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .mobile-details {
          .mobile-data {
            .order-icon {
              @include max-screen($md - 1) {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  &--versions {
    height: calc(100% - 132px);
    .table-row {
      .table-columns {
        @include media(mobile) {
          width: 100%;
        }
        &.multiple-values {
          @include media(mobile up) {
            width: 400px;
            padding-right: 20px;
          }
        }
      }
      &.table-header-row {
        .article-status {
          justify-content: flex-end;
          padding-right: 25px;
        }
      }
    }
    .table-data {
      padding: 0;
      .table-data-row {
        min-height: 48px;
        height: auto;
        border: none;
        border-bottom: 1px solid $border-color;
        background: transparent;
        border-radius: 0;
        &:last-child {
          border-bottom: none;
        }

        @include media(mobile) {
          flex-direction: column;
          align-items: flex-start;
        }

        @include media(desktop up) {
          padding: 0 40px;
        }
        .table-columns {
          @include media(mobile) {
            white-space: nowrap;
            padding-right: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &.article-status {
            @include min-screen($md) {
              justify-content: flex-end;
              .status {
                @include box-primary(false, 0, 5px, 0, 5px);
                @include flex-center-center;
                text-transform: uppercase;
                height: 20px;
                margin-right: 20px;
                font-weight: bold;
                font-size: 11px;
                line-height: 14px;
                &.in-development {
                  color: $jm-red;
                  border-color: $jm-red;
                }
                &.production-ready {
                  color: $mantis-green;
                  border-color: $mantis-green;
                }
                &.other {
                  color: $saffron;
                  border-color: $saffron;
                }
              }
            }
            .status-wrapper {
              display: flex;
              align-items: center;
              .status {
                width: 6px;
                height: 6px;
                background-color: $black-mine-shaft;
                border-radius: 50%;
                display: block;
                margin-right: 5px;
              }
              &.production-ready {
                color: $mantis-green;
                border-color: $mantis-green;
                .status {
                  background-color: $mantis-green;
                }
              }
              &.in-development {
                color: $jm-red;
                border-color: $jm-red;
                .status {
                  background-color: $jm-red;
                }
              }
              &.other {
                color: $saffron;
                border-color: $saffron;
                .status {
                  background-color: $saffron;
                }
              }
            }
          }
        }
      }
    }
  }

  &--admins {
    @include box-primary();
    overflow: hidden;

    .table-row {
      &.table-header-row {
        padding: 0 0 0 $section-spacing;
        height: $table-header-height;
        .jm-column {
          flex: 0 1 50%;
          &:hover {
            cursor: initial;
          }
          &.email {
            text-align: left;
            flex: 0 1 60%;
          }
        }
      }
      &.table-data-row {
        height: $table-row-height;
        justify-content: flex-start;
        border-radius: 0;
        margin-bottom: 0;
        border: none;
        background: $base;
        &:not(:last-child) {
          border-bottom: $border-width solid $border-color;
        }
        @include media(mobile) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0 $table-cell-padding 0 $section-spacing;
        }
        .table-columns {
          padding-right: $inner-spacing;
          width: 100% !important;
          @include media(mobile) {
            height: auto;
          }
          @include media(tablet up) {
            flex: 0 1 50%;
          }
          @include text-ellipsis-one-line();
          &.email {
            text-align: left;
            @include media(tablet up) {
              flex: 0 1 60%;
            }
          }
          &.user-name {
            font-weight: $bold;
          }
          .column-info {
            @include text-ellipsis-one-line();
          }
        }
      }
    }
    .table-data {
      padding: 0;
    }
  }
}
