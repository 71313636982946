// p-datepicker module

$m: p-datepicker;

.#{$m} {
  width: $datepicker-calendar-width !important;
  border: $border-width solid $border-color;
  border-radius: $box-border-radius;
  padding: 0;

  &:not(.p-disabled) {
    .p-yearpicker {
      &-year {
        &.p-highlight {
          background: $catskill-white;
        }
        &:not(.p-disabled) {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    table {
      td {
        span {
          &:not(.p-highlight),
          &:not(.p-diabled) {
            &:hover {
              background: $feijoa-green !important;
              color: $base;
            }
          }

          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  &-header {
    .p-datepicker-prev,
    .p-datepicker-next {
      .p-datepicker-prev-icon,
      .p-datepicker-next-icon {
        font-size: $caption;
      }
      &:hover {
        color: $mantis-green;
        .p-datepicker-prev-icon,
        .p-datepicker-next-icon {
          color: $mantis-green;
        }
      }
    }
    .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        &:enabled {
          &:hover {
            color: $mantis-green !important;
          }
        }
      }
    }
    .p-datepicker-prev,
    .p-datepicker-next {
      &:focus {
        box-shadow: none;
      }
    }
  }

  &-calendar-container {
    width: 100%;
    padding: 0 5px;
  }
  table {
    font-size: $label;
  }
  &-calendar {
    thead {
      tr {
        th {
          text-align: center;
          span {
            width: $datepicker-date-cell-size;
            height: $datepicker-date-cell-size;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: $datepicker-date-cell-padding;
          .p-element {
            width: $datepicker-date-cell-size;
            height: $datepicker-date-cell-size;
            &:hover {
              background: $feijoa-green;
            }
          }
          .p-highlight {
            background-color: $mantis-green !important;
            color: $base !important;
          }

          &.p-datepicker-today {
            .p-element {
              background: $catskill-white;
              &:hover {
                background: $catskill-white;
              }
            }
          }
        }
      }
    }
  }

  .p-monthpicker {
    &-month {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .p-yearpicker {
    &-year {
      &:not(.p-disabled) {
        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
}

.p-element,
.p-link {
  &:focus {
    box-shadow: none !important;
  }
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: none;
}
