@import '_vars';
@import '_mixin';
@import 'ui/_common';

.article-header-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include media(mobile) {
    font-size: $label;
  }
}

.sections-wrapper {
  .section {
    width: 100%;
    margin-bottom: 10px;
    .section-header {
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0 20px;
      font-weight: $bold;
      font-size: $label;
      line-height: 20px;
      color: $black-mine-shaft;

      @include media(tablet up) {
        font-size: $body;
      }

      .section-title {
        display: flex;
        align-items: center;
        max-width: 100%;
        overflow: hidden;
        .expand-icon {
          margin-right: 15px;
        }
        .name {
          @include text-ellipsis();
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .section-data {
      padding: 20px;
      background-color: $base;
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .component_item {
        width: 100%;
        @include media(tablet up) {
          width: calc(calc(100% / 3) - 14px);
        }
        .component-name {
          font-size: $caption_small;
          line-height: 15px;
          color: $black-mine-shaft;
          padding: 10px 15px;

          @include media(tablet up) {
            font-size: $caption;
          }
        }
        .component-value {
          font-weight: $bold;
          font-size: $label;
          line-height: 20px;
          color: $black-mine-shaft;
          padding: 0 15px 10px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .unit {
            font-size: $label;
            line-height: 1.285;
            color: $main-text-color;

            @include media(mobile) {
              font-weight: $normal;
            }
          }
          @include media(tablet up) {
            font-size: $body;
          }
        }
        .table-component-value {
          border-radius: 5px;
          border: 1px solid $border-color;
          .header {
            background: $alice-gray;
            border-bottom: 1px solid $border-color;
            box-sizing: border-box;
            border-radius: 5px 5px 0px 0px;
            font-weight: $bold;
            height: 48px;
            font-size: $caption;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black-mine-shaft;

            @include media(tablet up) {
              font-size: $body;
            }
          }
          &__content {
            overflow: auto;
            box-sizing: border-box;
            &::-webkit-scrollbar {
              height: 6px;
            }

            &::-webkit-scrollbar-track {
              background: $base;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: $mystic-gray;
            }
            .no-data {
              padding: 10px 20px;
              font-size: $caption;
            }
            .header-row {
              display: flex;
              height: 48px;
              .header-column {
                flex: 1;
                border-right: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: $caption;
                font-weight: $bold;
                @include media(mobile) {
                  min-width: 155px;
                }
                &:last-of-type {
                  border-right: none;
                }
              }
            }
            .data-row {
              display: flex;
              height: 48px;
              &:last-of-type {
                .data-column {
                  @include media(tablet up) {
                    border-bottom: none;
                  }
                }
              }
              .data-column {
                flex: 1;
                border-bottom: 1px solid $border-color;
                border-right: 1px solid $border-color;
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: $label;
                @include media(mobile) {
                  min-width: 155px;
                }
                &:last-of-type {
                  border-right: none;
                }
              }
              &:last-of-type {
                border-bottom: none;
              }
            }
            &--sample_holder {
              display: grid;
              grid-template-columns: 1fr;
              grid-gap: $section-spacing;
              padding: $section-spacing;
              @include media(tablet up) {
                grid-template-columns: repeat(2, 1fr);
              }

              @include min-screen($xxlg) {
                grid-template-columns: repeat(3, 1fr);
              }
              &__image {
                border-radius: $box-border-radius;
                width: $sample-img-sizing;
                height: $sample-img-sizing;
                border: $border-width solid transparent;
                transition: all $anim-time ease-in-out;

                &.image-not-found {
                  pointer-events: none;
                  cursor: default !important;
                  &:hover {
                    border: none !important;
                  }
                }

                &:hover {
                  cursor: zoom-in;
                  border: $border-width solid $mantis-green;
                }
              }
              .sample {
                &__holder {
                  display: flex;
                  gap: $section-spacing;
                  @include media(tablet up) {
                    align-items: center;
                  }
                }

                &__image {
                  border-radius: $box-border-radius;
                  width: $sample-img-sizing;
                  height: $sample-img-sizing;
                  border: $border-width solid transparent;
                  transition: all $anim-time ease-in-out;

                  &:hover {
                    cursor: zoom-in;
                    border: $border-width solid $mantis-green;
                  }
                }

                &__data {
                  .title {
                    font-size: $caption;
                    margin: 0;
                    line-height: 1;
                  }
                  .image-name {
                    font-size: $body;
                    font-weight: $bold;
                    margin: $xs-spacing 0;
                    line-height: 1.2;
                    @include text-ellipsis(1);

                    @include media(mobile) {
                      font-size: $label;
                      overflow-wrap: break-word;
                    }

                    @include media(tablet) {
                      margin: $xs-spacing 0;
                    }
                  }

                  .info {
                    display: flex;
                    gap: $sample-img-gap;
                    margin: 0;
                    padding: 0;
                    font-size: $caption;
                    @include media(mobile tablet) {
                      flex-direction: column;
                      gap: $xs-spacing;
                    }

                    .size {
                      position: relative;
                      &:after {
                        content: '|';
                        position: absolute;
                        right: -11px;
                        color: $border-color;
                        @include media(mobile tablet) {
                          display: none;
                        }
                      }
                    }

                    .added-by {
                      .user {
                        color: $jm-red;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .info-wrapper {
          display: flex;
          justify-content: space-between;
          gap: 15px;
          opacity: 1;
          transition: 0.25s all ease;
          border-top: 1px solid $border-color;
          padding: 15px 15px 5px 15px;
          &__thumbs-container {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            gap: 10px;
          }
          &__info-block {
            height: 36px;
            width: 36px;
            border-radius: 5px;
            cursor: pointer;
            &.notes-block {
              @include flex-center-center;
              border: 1px solid $black-mine-shaft;
              transition: 0.25s all ease-in-out;
              svg path {
                fill: $black-mine-shaft;
                transition: 0.25s all ease-in-out;
              }
              &:hover {
                border-color: $mantis-green;
                svg path {
                  fill: $mantis-green;
                }
              }
            }
            &.thumb {
              img {
                border-radius: 5px;
                width: 100%;
                height: 100%;
                margin: 0 auto;
              }
              &.active-image {
                opacity: 1;
                border: 2px solid $mantis-green;
              }
            }
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
        &:not(.table-component) {
          .component-name {
            border: 1px solid $border-color;
            border-bottom: none;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
          .component-value {
            border: 1px solid $border-color;
            border-top: none;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &.has-image-or-notes {
            .component-value {
              border-bottom: none;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }
            .info-wrapper {
              border: 1px solid $border-color;
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }
        }
        &.table-component {
          width: 100%;
        }
        &.table-component-with-image-or-notes {
          .table-component-value {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          .info-wrapper {
            border: 1px solid $border-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .image-preview {
        @include box-primary(false, 0, 0, 0, 0);
        width: 100%;
        min-height: 624px;
        @include media(mobile) {
          min-height: 200px;
        }
      }
    }
    &.expanded-section {
      height: auto;
      border-radius: 5px;
      .section-header {
        border-radius: 5px 5px 0px 0px;
        .section-title {
          .expand-icon {
            transform: rotate(180deg);
            svg path {
              fill: $mantis-green;
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }
}
