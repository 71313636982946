@import '_vars';
@import '_mixin';

.pulse {
  position: absolute;
  display: block;
  font-size: 20px;
  z-index: 999;

  &:before {
    content: '';
    position: absolute;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $alice-gray;
    opacity: 0.5;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $mystic-gray;
    opacity: 0.5;
    z-index: -1;
  }
}

.hide-srrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hidden {
  display: none;
}

.hidden-desktop {
  @include media(desktop up) {
    display: none !important;
  }
}

.hidden-mobile {
  @include media(mobile) {
    display: none !important;
  }
}

.hidden-tablet {
  @include media(tablet) {
    display: none !important;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.icon-arrow {
  cursor: pointer;
  svg path {
    fill: $black-mine-shaft;
  }
  &.left {
    transform: rotate(90deg);
  }
  &.up {
    transform: rotate(180deg);
  }
  &.right {
    transform: rotate(270deg);
  }
  &:hover,
  &.active {
    svg path {
      fill: $mantis-green;
    }
  }
}

@mixin text-ellipsis($lines-displayed: 2) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;

  @supports (-webkit-line-clamp: $lines-displayed) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $lines-displayed;
    -webkit-box-orient: vertical;
  }
}

@mixin text-ellipsis-one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-ellipsis {
  @include text-ellipsis;
}

.dashboard-page-wrapper {
  min-height: 200px;
  width: 100%;
  height: 100%;
  position: relative;
}

.hidden-900-up {
  @include min-screen($md-900) {
    display: none !important;
  }
}
