// templates box module
$m: templates-box;

.#{$m} {
  background: $base;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: $box-border-radius;
  padding: 0 10px 0 20px;
  margin-bottom: $section-spacing;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .text-wrap {
      display: flex;
      gap: $section-spacing;
      align-items: center;
      flex: 1;

      .icon {
        height: $body;
        width: auto;
        display: inline-block;
      }
      .text {
        font-size: $label;
        margin: 0;
        padding: 0;
        strong {
          font-weight: $bold;
        }

        @include media(mobile) {
          display: none;
        }
      }
    }
  }
}
