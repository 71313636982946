/* Chrome & Safari */
*::-webkit-scrollbar {
  width: $scrollbar-width; /*sets the thickness of vertical scroll  */
  height: $scrollbar-width; /*sets the thickness of horizontal scroll */
}

*::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border-radius: $scrollbar-thumb-border-radius;
  @include transition(0.2s);

  &:hover {
    background: $nevada-gray;
  }
}

*::-webkit-scrollbar-track {
  background: $track-color;
  border-radius: $scrollbar-thumb-border-radius;
}

html {
  /* IE */
  scrollbar-face-color: $scrollbar-color;
  scrollbar-shadow-color: $track-color;
  scrollbar-highlight-color: $track-color;
  /* FF */
  scrollbar-color: $scrollbar-color $track-color;
  scrollbar-width: thin;
}

//specific styling
.tiny-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 $scrollbar-color;
    border-radius: $scrollbar-thumb-border-radius;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    border-radius: $scrollbar-thumb-border-radius;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $nevada-gray;
  }
}

.cdk-global-scrollblock {
  overflow-y: scroll !important;
}
