@import '_vars';
@import '_mixin';
@import 'ui/_common';

//main gridster styling

.widgets {
  display: block;
  position: relative;

  &.has-widgets {
    @include media(tablet up) {
      margin-right: -20px;
    }
  }

  gridster {
    background: $alice-gray !important;
    padding: 0 !important;

    &.mobile {
      height: auto !important;
      gridster-item {
        height: auto !important;
      }
    }

    &-item {
      border-radius: $border-radius;
      background: none;
      &.gridster-item-moving {
        box-shadow: 0 0 5px rgb(0 0 0 / 9%), 0 6px 10px rgb(0 0 0 / 9%), 0 1px 18px rgb(0 0 0 / 9%);
        z-index: 2 !important;
      }
    }
  }

  &.dragging {
    gridster-item:not(.gridster-item-moving) {
      .widget:not(.add-widget) {
        opacity: 0.6;
      }
    }
  }

  .widget__header {
    display: flex;
    align-items: center;

    .title-section {
      svg {
        path {
          fill: $jm-red;
        }
      }
    }
  }

  &.edit {
    .widget__header {
      .title-section {
        .option-icon {
          display: block;
        }
        svg {
          display: block;
          height: $widget-drag-icon-height;
        }
      }
    }
  }
}
