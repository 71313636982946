@import '_vars';

.mat-form-field {
  width: 100%;
  .mat-form-field-infix {
    border: 1px solid rgba(108, 108, 108, 0.5);
  }
  &.mat-focused .mat-form-field-label {
    color: $gray;
  }
}
// .mat-select-panel,
// .mat-select-panel:not([class*='mat-elevation-z']) {
//   margin-top: 41px;
// }
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000000;
}
.mat-form-field-label:not(.mat-form-field-empty) {
  display: none !important;
}
.mat-form-field-type-mat-select .mat-form-field-label {
  width: calc(100% - 25px);
  margin-left: 7px;
}
.mat-form-field-label-wrapper {
  position: unset;
}
.mat-form-field-appearance-legacy {
  .mat-form-field-empty.mat-form-field-label {
    top: 19px;
  }
  .mat-form-field-underline {
    display: none;
  }
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: unset !important;
  }
}
.jakob-muller-select {
  margin-top: 30px !important;
}
