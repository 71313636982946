@import '_vars';
@import '_mixin';

//todo refactor this styling into a reusable component with its styline
//incorporate this ino app-header-bar component

.map-header {
  @include box-primary(false, 10px, 10px, 10px, 10px);
  height: $dashboard-bar-height;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px;

  &.with-selected-location {
    .first-part {
      &.map {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &.full-width {
    width: 100%;
    .first-part .map-title {
      @include min-screen($md) {
        margin-left: 10px;
      }
    }
    &.machine-details-component {
      @include max-screen($xxlg - 1) {
        width: 100%;
      }
    }
  }
  &.full-width-with-close-settings {
    margin: 23px 0 10px 0;
  }

  .map-title {
    font-weight: $bold;
    font-size: $label;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $black-mine-shaft;

    @include min-screen($md) {
      font-size: $title;
      line-height: 23px;
    }

    .selected-location-icon {
      margin-right: 15px;
    }
    .nav-bar-title {
      color: $black-mine-shaft;
      margin-right: 3px;
      @include max-screen($md - 1) {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 200px;
      }
      @include media(mobile) {
        font-size: $label;
        max-width: calc(100vw - 285px);
        min-width: 45px;
      }
      @include max-screen($mobile-small) {
        font-size: $caption;
      }
    }
    .additional-information {
      margin-left: 15px;
      border-left: 2px solid $border-color;
      padding-left: 15px;
      font-size: $title;
      line-height: 23px;
      color: $black-mine-shaft;
      @include media(mobile) {
        margin-right: $inner-spacing;
      }
      @include max-screen($mobile-small) {
        font-size: $caption;
      }
    }
  }
  .first-part {
    display: flex;
    .map-title {
      font-weight: $bold;
      font-size: $body;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: $black-mine-shaft;

      @include min-screen($desktop) {
        font-size: $title;
        line-height: 23px;
      }
      .selected-location-icon {
        margin-right: 15px;
      }
      .nav-bar-title {
        color: $black-mine-shaft;
        @include media(tablet) {
          margin-right: 10px;
        }
      }
      .additional-information {
        font-size: $body;
        font-weight: $normal;
        margin-left: 15px;
        border-left: 2px solid $border-color;
        padding-left: 15px;
        line-height: 20px;
        color: $black-mine-shaft;

        @include media(mobile) {
          font-size: $label;
        }
        @include max-screen($mobile-small) {
          font-size: $caption;
        }

        @include min-screen($desktop) {
          line-height: 23px;
        }
      }
    }
    .heart-beat {
      width: 30px;
      height: 30px;
      position: relative;
      margin-right: 8px;
      .big-circle {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .medium-circle {
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 11px);
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
      .small-circle {
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      &.active {
        .big-circle {
          border: 1px solid rgba(128, 201, 83, 0.15);
          animation: pulse 1s infinite ease-in-out;
          @include animation-delay(0.2s);
        }
        .medium-circle {
          border: 2px solid rgba(128, 201, 83, 0.5);
          animation: pulse 1s infinite ease-in-out;
        }
        .small-circle {
          background: $mantis-green;
        }
      }
      &.inactive {
        .big-circle {
          border: 1px solid $jm-red;
          display: none;
        }
        .medium-circle {
          border: 2px solid $jm-red;
          display: none;
        }
        .small-circle {
          background: $jm-red;
        }
      }
    }
  }

  .filter-icon {
    @include box-primary(false, 0, 10px, 0, 10px);
    background: $catskill-white;
    width: 112px;
    height: $input-height;
    font-weight: $bold;
    font-size: $label;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black-mine-shaft;
    @include transition(0.25s);

    @include media(mobile) {
      width: $input-height;
      gap: $xs-spacing;
    }
    &.show-active-machines {
      color: $jm-red;
      border-color: $jm-red;
      .setting-icon {
        svg path {
          fill: $jm-red;
        }
      }
    }

    .filter-title {
      @include media(mobile) {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
      background: $base;
    }
  }

  .zoom-buttons {
    display: flex;
    button {
      background: $base;
      border: 1px solid $border-color;
      box-sizing: border-box;
      width: $button-height;
      height: $button-height;
      @include flex-center-center;
      i {
        vertical-align: middle;
        &:hover {
          filter: drop-shadow(0 0 5px $gray);
        }
      }
      &:first-of-type {
        border-radius: 5px 0px 0px 5px;
      }
      &:last-of-type {
        border-radius: 0px 5px 5px 0px;
      }
    }
  }

  .link-icon {
    cursor: pointer;
    &:hover {
      svg path {
        fill: $mantis-green;
      }
    }
  }

  .detail-info {
    display: flex;
    align-items: center;
    gap: $elements-spacing;

    @include media(mobile) {
      width: 100%;
      flex-direction: column;
      margin-top: $elements-spacing;
      app-machine-status {
        width: 100%;
        .machine-status,
        .machine-another-status {
          flex: 1;
        }
      }
      jm-button {
        width: 100%;
        button {
          justify-content: center !important;
        }
      }
    }
  }

  &.machine-details-component {
    width: 100%;
    @include media(mobile) {
      height: auto;
      flex-direction: column;
      .first-part {
        width: 100%;
      }
    }
  }
}

.navigation-order-filters {
  display: flex;
  align-items: center;
  .order-icon {
    margin: 0 5px 0 5px;
    cursor: pointer;
    svg path {
      fill: $trout;
      @include transition(0.25s);
    }
    &.active-order-icon {
      svg path {
        fill: $jm-red;
      }
    }
    &:hover {
      svg path {
        fill: $mantis-green;
      }
    }
  }
}

.mobile-dashboard-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 64px;
  width: 100%;
  z-index: 10;
  box-shadow: 0px -3px 0px #dee6ec;
}

.sort-machine-wrapper {
  height: 80px;
  margin-bottom: 25px;
}
.filter-machine-wrapper {
  .filter-title {
    font-weight: $bold;
    font-size: $caption;
    line-height: 18px;
    color: $black-mine-shaft;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 15px;
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }
}

@include max-screen($md - 1) {
  .map-header {
    width: 100%;
    margin: 0;
    .first-part {
      .heart-beat {
        margin-right: $inner-spacing;
        margin-left: $inner-spacing;
      }
    }
    .zoom-buttons {
      button {
        width: 35px;
        height: 35px;
      }
    }
    &.with-selected-location {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}
