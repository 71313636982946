@import '_vars';
@import '_mixin';

.orders-wrapper {
  @include box-primary;
  overflow: auto;
  height: 100%;

  @include media(mobile) {
    width: 100%;
  }

  .table-header-row {
    display: flex;
    @include sticky();
    top: 0;
    z-index: 10;
    width: 100%;
    .table-columns {
      @include media(mobile) {
        width: 50% !important;
      }
    }
  }
  .table-data {
    @include media(tablet up) {
      height: auto;
    }

    cdk-virtual-scroll-viewport {
      height: 100%;
      width: 100%;
      min-height: calc(100vh - #{$orders-offset-mobile});

      @include media(tablet) {
        min-height: $orders-min-height-tablet;
      }

      @include media(desktop up) {
        min-height: calc(100vh - #{$orders-table-offset-desktop});
      }

      .cdk-virtual-scroll-content-wrapper {
        @include media(mobile) {
          position: relative;
        }

        .table-data-row {
          transition: 0.25s all ease;
          @include media(mobile) {
            flex-direction: column;
            align-items: flex-start;
            padding: 6px 5px 0 20px;
          }
          .table-columns {
            @include media(mobile) {
              width: 100%;
              height: 16px;
              display: flex;
              align-items: center;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
  .table-columns {
    @include media(tablet) {
      flex: 2;
      padding-right: 0 !important;
    }
    &.machine-name-column,
    &.order-name-column {
      @include media(tablet) {
        padding-right: 5px;
      }
    }
    &.status-column {
      display: flex !important;
      justify-content: center !important;
      .order-icon {
        height: 15px;
        width: 15px;
        svg {
          path {
            @include transition(0.2s);
          }
        }

        &:hover {
          svg {
            path {
              fill: $mantis-green;
            }
          }
        }
      }
    }
    &.remaining-column {
      @include media(tablet) {
        flex: 1;
      }
    }
  }
  .icon-container {
    width: 40px;
    height: 40px;
    z-index: 1;
    &.delete {
      @include box-primary;
      @include flex-center-center;
      &.disabled {
        cursor: not-allowed;

        svg-icon {
          svg path {
            fill: $main-text-color;
          }
        }
        &:hover {
          cursor: not-allowed;
        }
      }
      &.dequable {
        @include transition(0.25s);
        .delete-icon {
          svg path {
            @include transition(0.25s);
          }
        }

        &:hover {
          border-color: $jm-red;
          .delete-icon {
            svg path {
              fill: $jm-red;
            }
          }
        }
      }
    }
  }
  &.machine-orders {
    width: 100%;
    overflow: hidden;

    @include media(tablet) {
      min-height: $orders-min-height-tablet;
    }
    @include media(desktop up) {
      min-height: calc(100vh - 488px);
    }

    @include max-screen-height(667px) {
      min-height: 290px;
    }

    .table-row.table-header-row {
      padding: 0 30px 0 55px;
    }
    .table-data {
      cdk-virtual-scroll-viewport {
        @include max-screen-height(667px) {
          min-height: calc(100vh - 280px);
        }
      }
      height: auto;
      .table-data-row {
        @include media(mobile) {
          padding-left: 10px !important;
          min-height: 48px;
          height: auto;
        }
        .order-status-column {
          justify-content: flex-end;
          @include media(mobile) {
            justify-content: flex-start;
            padding-bottom: 10px;
            margin-top: 10px;
          }
          .order-status {
            @include box-primary;
            margin-right: 10px;
            padding: 3px 6px;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 11px;
            line-height: 14px;
            border: 1px solid;
            background-color: $catskill-white;
            display: flex;
            align-items: center;
            .order-icon {
              margin-right: 5px;
            }
            &.finished {
              border-color: $mantis-green;
              color: $mantis-green;
              .order-icon svg path {
                fill: $mantis-green;
              }
            }
            &.removed {
              border-color: $regent-gray;
              color: $regent-gray;
              .order-icon svg path {
                fill: $regent-gray;
              }
            }
            &.failed {
              border-color: $cinnabar-red;
              color: $cinnabar-red;
              .order-icon svg path {
                fill: $cinnabar-red;
              }
            }
          }
          .status-column {
            display: flex;
            align-items: center;
            .status {
              border-radius: 80px;
              width: 6px;
              height: 6px;
              margin-right: 10px;
            }
            &.finished {
              .status {
                background-color: $mantis-green;
              }
              .status-title {
                color: $mantis-green;
              }
            }
            &.removed {
              .status {
                background-color: $regent-gray;
              }
              .status-title {
                color: $regent-gray;
              }
            }
            &.failed {
              .status {
                background-color: $jm-red;
              }
              .status-title {
                color: $jm-red;
              }
            }
          }
        }
        .mobile-row-table {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          .order-name {
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
          }
          .article-name {
            font-size: 10px;
            line-height: 12px;
            color: $main-text-color;
          }
          &:first-of-type {
            padding: 10px 0 5px 0;
          }
        }
      }
      .table-row-wrapper {
        margin-bottom: 10px;
        display: flex;
        .table-data-row {
          width: calc(100% - 14px);
          .button-more-options {
            @include box-primary;
            @include flex-center-center;
            min-height: 36px;
            min-width: 36px;
            @include transition(0.2s);

            svg path {
              @include transition(0.2s);
            }

            .option-icon {
              display: flex;
            }
            &.disabled {
              opacity: 0.5;
            }

            &:hover {
              border: 1px solid $mantis-green;
            }

            &:hover:not(.disabled) .option-icon {
              svg path {
                fill: $mantis-green;
              }
            }
          }
          &.active-orders {
            background-color: $base;
          }
          &.warning-message {
            margin-bottom: 10px;
            background: rgba(239, 65, 35, 0.05);
            border-color: $jm-red;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            div.message {
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: $jm-red;
            }
          }
        }
        .active-order {
          width: 12px;
          margin-right: 14px;
          display: flex;
          justify-content: center;
          .border {
            width: 4px;
            background: $mantis-green;
            border-radius: 5px;
            @include media(mobile) {
              width: 8px;
            }
          }
        }
        .empty-div {
          width: 12px;
          margin-right: 14px;
        }
        .drag-icon {
          display: flex;
          align-items: center;
          margin-right: 14px;
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      div.dash-line {
        border: 1px dashed $heather-gray;
        margin: 15px 0;
        border-image: url('/assets/images/border.jpg') 2 2 round;
      }
    }
    &.orders-history {
      .table-row.table-header-row {
        padding: 0 15px;
        @include media(tablet up) {
          padding: 0 30px;
        }
        .table-columns {
          &.sortable {
            justify-content: flex-start;
            .sort-icon-wrapper {
              @include media(mobile) {
                display: none;
              }
            }
          }
          &.order-status-column {
            justify-content: flex-end;
            padding-right: 40px;
          }
        }
      }
    }
  }

  &.orders-history {
    @include media(tablet) {
      min-height: calc(100vh - 488px);
    }
    @include media(desktop up) {
      min-height: calc(100vh - 545px);
    }
    .table-data {
      cdk-virtual-scroll-viewport {
        @include media(tablet) {
          min-height: calc(100vh - 578px);
        }
        @include media(desktop up) {
          min-height: calc(100vh - 634px);
        }
      }
    }
  }

  &--list {
    .table-data {
      cdk-virtual-scroll-viewport {
        min-height: calc(100vh - 340px);

        @include media(desktop up) {
          min-height: calc(100vh - 364px);
        }
      }
    }
  }
}
