@import '_vars';
@import '_mixin';

.quick-form-control {
  label {
    font-weight: $bold;
    font-size: $caption;
    line-height: 18px;
    color: $black-mine-shaft;
    text-overflow: ellipsis;
    height: 15px;
    white-space: nowrap;
    margin-bottom: 8px;

    .error-message {
      color: $jm-red;
      margin-left: 5px;
    }
  }

  .input-wrapper {
    width: 100%;
    position: relative;
    input {
      height: $input-height;
      width: 100%;
      background: rgba(239, 243, 246, 0.35);
      border: 1px solid $input-border;
      border-radius: 5px;
      box-sizing: border-box;
      color: $black-mine-shaft;
      font-size: $label;
      line-height: 18px;
      padding: 0 15px;
      @include transition(0.2s);

      &[type='text'] {
        padding: 0 15px;
        text-align: left;
      }
      &:disabled {
        border: 1px solid $input-border-disabled !important;
        color: $button-disabled-text !important;
        background: $alice-gray;
        cursor: not-allowed;
      }
      &:focus {
        border: 1px solid $feijoa-green;
        box-sizing: border-box;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
      }
      &.readonly {
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 35px 0 15px;

        &:hover {
          background: #f9fbfc;
        }
        &:focus {
          border: 1px solid $input-border;
          box-shadow: none;
        }
      }
      &.hide-arrows {
        text-align: left;
        padding: 0 15px;
      }
      &.password-field {
        -webkit-text-security: disc;
      }
    }
    img {
      &.icon {
        position: absolute;
        top: calc(50% - 8px);
        right: 15px;
      }
      &.image-pointer {
        cursor: pointer;
      }
    }
  }

  .ng-touched.ng-invalid > .input-wrapper > input {
    border: 1px solid $jm-red;
  }

  .help-message {
    font-size: 12px;
    line-height: 21px;
    color: $main-text-color;
    margin: 3px 0 0 0;
  }

  ::placeholder {
    font-size: $label;
    line-height: $input-height;
    color: $main-text-color;
  }

  :-moz-placeholder {
    font-size: $label;
    line-height: $input-height;
    color: $main-text-color;
  }

  :-ms-input-placeholder {
    font-size: $label;
    line-height: $input-height;
    color: $main-text-color;
  }

  ::-ms-input-placeholder {
    font-size: $label;
    line-height: $input-height;
    color: $main-text-color;
  }
}

.validation-message {
  font-size: 12px;
  line-height: 21px;
  color: $jm-red;
  margin: 3px 0 0 0;
}
