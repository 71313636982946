@import '_vars';
@import '_mixin';
@import 'ui/_common';
//general layout settings

.container {
  max-width: 100%;
  @include min-screen($container-max-width) {
    max-width: $container-max-width;
  }
}

body {
  position: relative;
  height: 100%;
}

body {
  min-height: 100%;
}
html,
body {
  min-height: 100%;
}

//ng-dropdown-panel styling - appended ng select to the body

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      height: $input-height;
      max-height: $input-height;
      margin: 0px !important;
      display: flex !important;
      align-items: center;
      padding-left: $elements-spacing;
      span.ng-option-label {
        font-size: $label;
        line-height: 18px;
        color: $black-mine-shaft;
        @include text-ellipsis(1);
      }
      &.ng-option-marked {
        background-color: $alice-gray;
      }
      &.ng-option-selected {
        background-color: $alice-gray;
      }
    }
  }
  .scroll-host {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
      outline: $border-width solid $scrollbar-color;
    }
  }
  &.ng-select-bottom {
    margin-top: 0;
    border: $border-width solid $feijoa-green !important;
    border-top: 0px !important;
  }
}
