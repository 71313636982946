//**************************************************
// DEFAULT TYPOGRAPHY SETTINGS
//**************************************************

//DEFAULT

$font-family-base: 'Mulish', sans-serif;

html,
body {
  font-size: $body !important;
}

body {
  margin: 0 !important;
  font-family: $font-family-base !important;
  background-color: $base !important;
  overflow-y: auto !important;
}

*:focus {
  outline: none;
}

input,
textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

p {
  font-size: $label;
}

h1 {
  font-size: $heading-xl;
}

h2 {
  font-size: $heading-large;
}

h3 {
  font-size: $heading-large;
}

h4 {
  font-size: $heading;
}
