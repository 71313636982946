@import '_vars';
@import '_mixin';
@import 'ui/_common';

// search-box
$s: search-box;

.#{$s} {
  background: $base;
  border: $border-width solid $border-color;
  border-radius: $box-border-radius;
  width: 100%;
  height: $search-bar-height;
  margin-bottom: $inner-spacing;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $inner-spacing;

  &__search {
    flex: 1;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: $inner-spacing;
    flex: 0 1 auto;
  }
  &--sticky {
    @include sticky();
    top: $inner-spacing;
    z-index: 2;
    &:before {
      content: '';
      height: $inner-spacing;
      width: calc(100% + (#{$border-width} * 2));
      top: -11px;
      left: -1px;
      position: absolute;
      background: $alice-gray;
    }
    @include media(desktop up) {
      top: $boxes-offset;
      &:before {
        content: '';
        height: $section-spacing;
        width: calc(100% + (#{$border-width} * 2));
        top: -21px;
        left: -1px;
        position: absolute;
        background: $alice-gray;
      }
    }
  }
}

.p-component {
  font-family: $font-family-base;
}

.p-element {
  &.primary {
    .p-datatable {
      .p-sortable-column {
        &:focus {
          box-shadow: none;
        }
        &:not(.p-highlight) {
          &:hover {
            background: transparent;
          }
        }
      }
      &-thead {
        height: $table-header-height;
        background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%), $base;
        border: $border-width solid $border-color;
        border-radius: $table-border-radius $table-border-radius 0px 0px;
        &:after {
          @include media(desktop) {
            content: '';
            position: absolute;
            height: $table-header-shadow-height;
            width: 100%;
            bottom: -11px;
            left: 0;
            background: linear-gradient(180deg, $alice-gray, transparent);
          }
        }

        tr {
          th {
            height: $table-header-height;
            border: none;
            background: transparent;
            padding: 0;
            @include media(mobile) {
              padding: 0 20px;
            }
            .cell {
              display: flex;
              align-items: center;
              font-size: $caption;
              line-height: 1.25;
              color: $black-mine-shaft;
              padding: $table-cell-padding;
              @include media(mobile) {
                padding: 0 $table-cell-padding 0 0;
              }
              &.p-sortable-column {
                .sorting-icons {
                  margin-left: 5px;
                  width: 16px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }
                .sort-icon-arrow {
                  svg {
                    width: 10px;
                    height: 5px;
                    path {
                      transition: fill 0.25s ease-in-out;
                    }
                  }

                  &:hover {
                    svg {
                      path {
                        fill: $mantis-green;
                      }
                    }
                  }

                  &.active {
                    svg path {
                      fill: $black-mine-shaft;
                    }
                  }
                  &.asc svg {
                    transform: rotate(180deg);
                    margin-bottom: 3px;
                  }
                }
                &.p-highlight {
                  background: transparent;
                }
                &.p-highlight[aria-sort='ascending'] {
                  .sort-icon-arrow.active {
                    &.asc {
                      svg {
                        path {
                          fill: $trout;
                        }
                      }
                    }
                  }
                }
                &.p-highlight[aria-sort='descending'] {
                  .sort-icon-arrow.active {
                    &.desc {
                      svg {
                        path {
                          fill: $trout;
                        }
                      }
                    }
                  }
                }
              }
              .filter-icon {
                margin-left: 7px;
              }
              &.extra-small {
                @include media(desktop) {
                  min-width: $table-xs-cell-width;
                }
              }
              &.small {
                @include media(desktop) {
                  min-width: $table-small-cell-width;
                }
              }
              &.medium {
                @include media(tablet) {
                  min-width: $table-medium-cell-width;
                }
              }
              &.large {
                @include media(desktop) {
                  min-width: $table-large-cell-width;
                }
              }
              &.extra-large {
                @include media(desktop) {
                  min-width: $table-extra-large-cell-width;
                }
              }
            }
            &.toggle-row-cell {
              flex: 0 0 $toggle-item-width;
              .cell {
                width: 24px;
              }
            }
            &.empty-space {
              flex: 0 0 $section-spacing;
            }
            &.mobile-header {
              display: flex;
              justify-content: space-between;
              .arrow-wrapper {
                padding: 15px;
                margin-right: -15px;
              }
            }
            &:first-of-type {
              border-radius: $table-border-radius 0 0 0;
            }
            &:last-of-type {
              border-radius: 0 $table-border-radius 0 0;
            }
          }
        }
      }

      &-tbody {
        border: $border-width solid $border-color;
        border-radius: 0 0 $box-border-radius $box-border-radius;
        overflow: hidden;
        @include media(mobile) {
          border-top: none;
        }
        tr {
          min-height: $table-row-height;
          height: $table-row-height;
          transition: background 0.2s ease-in-out;
          transition-delay: 0.15s;
          position: relative;
          &:not(:last-child) {
            border-bottom: $border-width solid $border-color;
          }

          &:hover {
            background: $catskill-white;
          }
          td {
            border: none;
            padding: $table-cell-padding $table-cell-padding $table-cell-padding 0;

            @include media(mobile) {
              flex-direction: column;
              padding: 10px 20px;
              .cell {
                width: 100%;
              }
              .extra-column {
                width: 100%;

                &:empty {
                  display: none;
                }

                td {
                  padding: 0;
                  .cell {
                    color: $regent-gray;
                    font-size: $caption_small;
                    font-weight: $bold;
                  }
                }
              }
            }
            .cell {
              font-weight: $normal;
              font-size: $label;
              line-height: 1.28;
              color: $black-mine-shaft;
              word-break: break-all;
              @include text-ellipsis(2);
              &.extra-small {
                @include media(desktop) {
                  min-width: $table-xs-cell-width;
                }
              }
              &.small {
                @include media(desktop) {
                  min-width: $table-small-cell-width;
                }
              }
              &.medium {
                @include media(tablet) {
                  min-width: $table-medium-cell-width;
                }
              }
              &.large {
                @include media(desktop) {
                  min-width: $table-large-cell-width;
                }
              }
              &.extra-large {
                @include media(desktop) {
                  min-width: $table-extra-large-cell-width;
                }
              }
              &.table-tag {
                font-size: $table-tag;
                font-weight: $bold;
                padding: 4px 5px;
                display: flex !important;
                flex-direction: row;
                align-items: center;
                line-height: 1;

                @include media(tablet up) {
                  text-transform: uppercase;
                  background: $main-text-color;
                  @include background-opacity($main-text-color, 0.1);
                  border-radius: $box-border-radius;
                }

                @include media(mobile) {
                  border-radius: $box-border-radius;
                  padding: $table-cell-padding;
                  width: auto;
                }

                .image-url {
                  margin-right: $inner-spacing;
                }

                &.danger {
                  color: $jm-red;
                  background-color: $white;
                  border: $border-width solid $jm-red;
                }

                &.success {
                  color: $mantis-green;
                  background-color: $white;
                  border: $border-width solid $mantis-green;
                }
                &.warning {
                  color: $yellow-1;
                  background-color: $white;
                  border: $border-width solid $yellow-1;
                }

                &.disable {
                  color: $silver-chalice;
                  background-color: $white;
                  border: $border-width solid $silver-chalice;
                }
              }
              &.image-preview {
                display: flex;
                align-items: center;
                img {
                  width: 36px;
                  height: 36px;
                  border-radius: $box-border-radius;
                  margin-right: 5px;
                  border: $border-width solid transparent;
                  cursor: pointer;
                  &.image-not-found {
                    pointer-events: none;
                    cursor: default;
                    &:hover {
                      border: none;
                    }
                  }
                  &:hover {
                    border-color: $mantis-green;
                  }
                }
              }
              &.not-applicable {
                color: $main-text-color;
                font-weight: $bold;
              }
              &.bold {
                font-weight: $bold;
              }
            }
            &.mobile-right-align {
              @include media(mobile) {
                align-items: flex-end;
              }
            }
            &.toggle-row-cell {
              width: $toggle-item-width;
              padding: 0 15px;
              flex: 0 0$toggle-item-width;
              @include media(desktop up) {
                flex: 0 0 $toggle-item-width;
              }
              .cell {
                width: $expand-arrow-dimension;
                height: $expand-arrow-dimension;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                  .expand-arrow {
                    border-color: $mantis-green;
                    svg {
                      path {
                        fill: $mantis-green;
                      }
                    }
                  }
                }
                .expand-arrow {
                  border: $border-width solid;
                  border-color: $border-color;
                  border-radius: 50%;
                  width: $expand-arrow-dimension;
                  height: $expand-arrow-dimension;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: all 0.25s ease-in-out;
                  svg {
                    margin-top: 2px;
                    path {
                      fill: $black-mine-shaft;
                    }
                  }
                }
                &.expanded {
                  .expand-arrow {
                    border-color: $mantis-green;
                    svg {
                      margin-top: 0px;
                      transform: rotate(180deg);
                      path {
                        fill: $mantis-green;
                      }
                    }
                  }
                }
              }
            }
            &.empty-space {
              flex: 0 0 $section-spacing;
            }
            &.no-data-for-display {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 100px;
              font-size: $label;
            }
          }
          .more-options {
            @include box-primary;
            @include flex-center-center;
            @include transition(0.2s);
            position: absolute;
            right: 10px;
            top: calc(50% - 18px);
            height: 36px;
            width: 36px;
            cursor: pointer;

            svg path {
              @include transition(0.2s);
            }

            .option-icon {
              display: flex;
            }
            &:hover {
              border: 1px solid $mantis-green;
            }
            &.disabled {
              opacity: 0.5;
              border: 1px solid $border-color;
              pointer-events: none;
            }

            &:hover:not(.disabled) .option-icon {
              svg path {
                fill: $mantis-green;
              }
            }
          }
          &.active {
            background-color: $frost-green;

            td .cell.success {
              background-color: $mantis-green;
              color: $base;
            }
          }
          &.expanded-row {
            background-color: $catskill-white;
          }
          &.inactive {
            background-color: $alice-gray;
            .cell {
              color: $main-text-color;
              &.message-content {
                font-weight: $bold !important;
              }
            }
          }
          &.deleted {
            background-color: $light-red !important;
          }
          &.unread {
            .cell {
              &.message-content {
                font-weight: $bold !important;
              }
            }
          }
          &.row-expansion {
            background: $catskill-white;
            height: auto;
            @include media(mobile) {
              flex-direction: column;
            }
            td {
              padding: 28px 54px;
              border: none;
              @include media(mobile) {
                padding: 15px 20px;
                flex-direction: column;
              }
            }
            app-audit-logs-expanded {
              width: 100%;
            }
            &.default-mobile-columns {
              border-bottom: 0;
              .mobile-expanded-default-column {
                display: flex;
                gap: 10px;
                align-items: baseline;
                @include media(mobile) {
                  margin-bottom: 10px;
                }
                .cell-title {
                  margin-bottom: 0;
                  @include media(mobile) {
                    flex: 0 0 50%;
                    @include text-ellipsis(4);
                    word-break: break-word;
                  }

                  .title {
                    font-size: $caption;
                    color: $black-mine-shaft;
                    line-height: 1.25;

                    @include media(tablet up) {
                      font-weight: $bold;
                    }
                  }
                }
                td {
                  padding: 0;
                  .cell {
                    font-weight: $bold;
                    @include media(mobile) {
                      font-size: $caption;
                      line-height: 1.285;
                      @include text-ellipsis(4);
                    }
                  }
                }
              }
            }
          }
          &.loading-row {
            height: 100%;
            width: 100%;
            border: 0;
            td {
              width: 100%;
              border: 0;
              @include flex-center-center;
            }
          }
          &.row-has-menu-options {
            &.hasRowTableTag {
              td:nth-child(2) {
                @include media(mobile) {
                  display: flex;
                  flex-direction: row;
                  max-width: calc(100% - #{$more-options-btn-sizing} - #{$inner-spacing});
                  padding: $inner-spacing 0 $inner-spacing $section-spacing;
                }
                div.cell:nth-child(1) {
                  @include media(mobile) {
                    padding-right: $inner-spacing;
                  }
                }
                .message-content {
                  @include media(mobile) {
                    flex: 0 1 auto;
                    padding-right: $inner-spacing;
                    font-size: $caption;
                  }
                }
                .extra-column {
                  @include media(mobile) {
                    flex: 0 1 1;
                    padding: 0 $inner-spacing 0 0;
                    jm-extra-mobile-cell {
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                      jm-string-cell {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
          &.no-data-table-holder {
            min-height: 220px;
            @include media(tablet up) {
              min-height: 440px;
            }
            app-empty-block {
              width: 100%;
            }
          }
          &:first-of-type {
            td {
              border-top: 0;
            }
          }
        }
      }

      &-hoverable-rows {
        .p-datatable-tbody > tr {
          cursor: pointer;
          &:not(.p-highlight):hover {
            background-color: $catskill-white;
          }
        }
      }

      &-loading-overlay {
        display: none;
      }
    }

    &.no-border {
      .p-datatable-thead {
        border-radius: none !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
      .p-datatable-tbody {
        border: none !important;
        tr:last-child {
          border-bottom: $border-width solid $border-color !important;
        }
      }
    }
  }
  &.overflow-table {
    .p-datatable {
      overflow-y: unset;
      overflow-x: auto;
      .p-datatable-wrapper {
        height: calc(
          100vh - #{$header-height} - #{$main-content-padding} - #{$section-title-height} - #{$section-title-height} - #{$section-spacing}
        );
        .p-datatable-table {
          height: 100%;
          background: $base;
          .p-datatable-thead {
            top: 0;

            tr {
              th {
                padding: 0 5px;
                &.mobile-header {
                  //Mario to fix header on mobile & tablet
                }
              }
            }
          }
        }
      }
      &-tbody {
        border-bottom: 0;
        tr {
          td {
            padding: 0 5px;
            @include media(mobile tablet) {
              flex: 0 0 $table-medium-cell-width !important;
              width: $table-medium-cell-width;
            }
          }
        }
      }
    }
    .p-datatable-tbody {
      tr {
        flex: 0 0 auto;
        td {
          padding: 0 5px;
          @include media(mobile tablet) {
            flex: 0 0 $table-medium-cell-width !important;
            width: $table-medium-cell-width;
          }
        }
      }
    }
  }
}

.p-datatable-scrollable {
  .p-datatable-thead,
  .p-datatable-tbody,
  .p-datatable-tfoot {
    display: block;
  }

  .p-datatable-thead > tr,
  .p-datatable-tbody > tr,
  .p-datatable-tfoot > tr {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    display: flex;
    flex: 1 1 0;
    align-items: center;
  }
}

.p-column-filter-menu-button {
  width: 24px;
  height: 24px;
  margin-left: 3px;
  border-radius: 50%;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background: $filter-hover-bg;
  }

  &:focus {
    box-shadow: none;
  }

  &.p-column-filter-menu-button-active {
    background: $filter-active-bg;

    path {
      fill: $mantis-green;
    }

    &:hover,
    &:active,
    &:focus {
      background: $filter-active-bg;
    }
  }
}

.p-column-filter-overlay {
  padding: $filter-box-spacing;
  min-width: $table-filter-width !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09) !important;
  border-radius: $box-border-radius !important;
  .p-column-filter-header {
    font-size: $label;
    font-weight: $bold;
    color: $black-mine-shaft;
  }
  .p-column-filter-constraints {
    .p-column-filter-constraint {
      padding: 0;
      .p-calendar {
        margin: $filter-box-spacing 0;
      }
      .action-wrapper {
        display: flex;
        gap: 10px;
        jm-button {
          flex: 0 1 auto;
        }
      }
    }
  }
  .p-column-filter-buttonbar {
    display: none;
  }
}

@mixin column-setting-row {
  height: 60px;
  padding: 22px 15px;
  border-bottom: $border-width solid $border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .column-wrapper {
    display: flex;
    gap: 15px;
    span.label {
      color: $black-mine-shaft;
      font-size: $label;
      font-weight: $bold;
      line-height: $label;
    }
    .drag-icon:hover {
      cursor: pointer;
      svg path {
        fill: $mantis-green;
      }
    }
  }

  &.unselect {
    .column-wrapper span.label {
      color: $main-text-color;
    }
  }
}

.column-settings-wrapper {
  width: 320px;
  .column-settings-list {
    height: 300px;
    overflow-y: auto;
    .column-settings-row {
      @include column-setting-row;
    }
  }
  .error-message {
    font-size: $caption;
    line-height: 21px;
    color: $jm-red;
    padding: 15px 15px 0px;
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 15px 14px 15px;
    .action-buttons-wrapper {
      display: flex;
      gap: 10px;
    }
    span.reset {
      color: $jm-red;
      font-size: $label;
      font-weight: $bold;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.cdk-drag-preview.column-settings-row {
  @include column-setting-row;
  background-color: $base;
}

// sticky scroll header
$m: sticky-header;

.#{$m} {
  position: relative;
  &--after-header-bar {
    .p-datatable {
      .p-datatable-wrapper {
        overflow: unset;
        .p-datatable-table {
          .p-datatable-thead {
            @include sticky();
            top: calc(#{$search-bar-height} + (#{$inner-spacing} * 2));
            &:before {
              content: '';
              height: $inner-spacing;
              width: calc(100% + (#{$border-width} * 2));
              top: -11px;
              left: -1px;
              position: absolute;
              background: $alice-gray;
            }
            @include media(desktop up) {
              top: calc(
                #{$header-height} + #{$search-bar-height} + #{$section-title-height} + (#{$section-spacing} * 2) + #{$inner-spacing}
              );
            }
          }
        }
      }
    }
  }
}
