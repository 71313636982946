@import 'ui/_common';
// Main app layout holder
$m: main-holder;

.#{$m} {
  padding: 20px 10px;

  @include min-screen($lg) {
    padding: 20px;
  }

  .jm-loader {
    margin: 0 auto;
  }

  &__content {
    max-width: $container-max-width;
    width: 100%;
    margin: 0 auto;
    height: auto;
    overflow-y: auto;
    display: flex;
    overflow: hidden;

    @include min-screen($desktop) {
      height: calc(100vh - #{$header-plus-footer-height});
    }

    .module-sidebar {
      margin-right: 20px;
      z-index: 2;
      border-radius: 5px;
    }

    .main-content {
      width: 100%;
      height: 100%;

      &__table {
        position: relative;
      }
    }
  }

  &__inner {
    height: 100%;
  }

  &__main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    overflow: auto;
    @include media(tablet up) {
      flex-direction: row;
    }
  }

  &__half {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    @include media(tablet up) {
      flex: 0 1 50%;
      max-width: 50%;
    }
    app-box {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .box {
      border: 1px solid $border-color;
      border-radius: 5px;
      background: $base;
      overflow: auto;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      &__title {
        background: linear-gradient(180deg, $base 0%, #f7f9fb 100%);
        height: 60px;
        min-height: 60px;
        padding: 0 20px;
        font-weight: $bold;
        font-size: $label;
        line-height: 26px;
        display: flex;
        align-items: center;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid $border-color;
      }
      &__content {
        background: #ffffff;
        padding: 20px;
        overflow: auto;
        height: 100%;
        border-radius: 5px;

        .manage-button {
          min-width: 160px;
          margin-top: $section-spacing;
        }
      }
    }
  }

  &--dashboard {
    padding: 20px;
    @include max-screen($md) {
      padding: 20px 10px 0 10px;
    }

    .dashboard-wrapper {
      .dashboard-content-data {
        .module-sidebar {
          height: 100%;
        }
      }
    }
  }

  &--analytics {
    .#{$m} {
      &__content {
        .main-content {
          .analytics-data-view {
            height: 100%;

            .analytics-table-view {
              height: 100%;
              .table-introduction {
                background: linear-gradient(180deg, $base 0%, rgba(239, 243, 246, 0.5) 100%);
                border: 1px solid $border-color;
                box-sizing: border-box;
                border-radius: 5px 5px 0px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                height: 60px;
                div.title {
                  font-weight: $bold;
                  font-size: $label;
                  line-height: 18px;
                }
              }
              .my-analytics-data-wrapper.jakob-muller-table {
                @include box-primary;
                padding: 20px 10px;
                max-height: calc(100% - 130px);
                overflow: auto;
                border-radius: 0 0 5px 5px;
                border-top: 0;
                display: flex;
                flex-direction: column;
                @include media(mobile) {
                  padding: 10px;
                }
                .table-row {
                  padding: 0 20px;
                  @include media(mobile) {
                    .table-columns {
                      width: 100% !important;
                    }
                  }
                  &.table-header-row {
                    border: 1px solid $border-color;
                    height: 60px;
                    min-height: 60px;
                    border-radius: 5px 5px 0px 0px;
                    .table-columns {
                      font-weight: $bold;
                      font-size: $caption;
                      line-height: 15px;
                      color: $black-mine-shaft;
                    }
                  }
                  &.footer-row {
                    border: 1px solid $border-color;
                    border-top: 0;
                    border-radius: 0 0 5px 5px;
                    @include media(mobile) {
                      flex-wrap: wrap;
                      height: auto;
                      padding: 10px 20px;
                    }
                    @include media(tablet up) {
                      height: 60px;
                      min-height: 60px;
                    }
                    .table-columns {
                      .mobile-detail-title {
                        margin-right: 10px;
                        min-width: 80px;
                        font-weight: $normal;
                        font-size: $caption;
                      }
                    }
                  }
                  &.table-data-row {
                    border-radius: 0;
                    border-top: 0;
                    margin-bottom: 0;
                    background: $base;
                    @include media(mobile) {
                      .table-columns {
                        height: 48px;
                      }
                    }
                  }
                  &.active-table-data-row {
                    display: block;
                    border: 1px solid $border-color;
                    border-top: none;
                    background: $catskill-white;
                  }
                }
                .table-data {
                  padding: 0;
                  @include media(tablet up) {
                    max-height: calc(100% - 130px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &--alerts {
    .#{$m} {
      &__main-content {
        overflow: unset;
      }
      &__content {
        height: 100%;
        min-height: calc(100vh - #{$header-plus-footer-height});

        height: initial;
        overflow: unset;

        .main-content {
          height: initial;

          .sticky-holder {
            z-index: 2;
            @include media(desktop up) {
              top: $main-offset;
              &:before {
                content: '';
                height: 24px;
                width: 100%;
                top: -24px;
                position: absolute;
                background: $alice-gray;
              }
            }

            app-header-bar {
              @include media(desktop up) {
                @include sticky();
                top: $main-offset;
                &:after {
                  content: '';
                  position: absolute;
                  height: 10px;
                  width: 100%;
                  left: 0;
                  bottom: -11px;
                  background: linear-gradient(180deg, $alice-gray, transparent);
                }
              }
            }
          }
        }
      }
    }
  }

  &--admin {
    .#{$m} {
      &__main-content {
        overflow: unset;
      }
      &__content {
        height: 100%;
        min-height: calc(100vh - #{$header-plus-footer-height});

        height: initial;
        overflow: unset;

        .module-sidebar--admin {
          height: 100%;
          @include media(desktop up) {
            @include sticky();
            top: $main-offset;
            max-height: calc(100vh - #{$header-height} - #{$section-spacing} - #{$section-spacing});
          }
        }
        .main-content {
          height: initial;

          .sticky-holder {
            z-index: 2;
            @include media(desktop up) {
              top: $main-offset;
              &:before {
                content: '';
                height: 24px;
                width: 100%;
                top: -24px;
                position: absolute;
                background: $alice-gray;
              }
            }

            app-header-bar {
              @include media(desktop up) {
                @include sticky();
                top: $main-offset;
                &:after {
                  content: '';
                  position: absolute;
                  height: 10px;
                  width: 100%;
                  left: 0;
                  bottom: -11px;
                  background: linear-gradient(180deg, $alice-gray, transparent);
                }
              }
            }

            app-a-article-template-form {
              height: 100%;
              .article-template-form-wrapper {
                .build-template {
                  height: 100%;
                  .section-builder-desktop-view {
                    app-a-section-builder {
                      height: 100%;
                    }
                  }
                }
              }
            }
          }

          app-a-image-gallery,
          app-a-upt-patterns {
            app-media-gallery {
              .sticky-holder {
                app-header-bar {
                  @include media(desktop up) {
                    margin-bottom: 20px;
                  }
                }
              }
              .image-gallery {
                background: transparent;
                border: none;
                padding: 0;

                .folders {
                  background: $base;
                  @include sticky();
                  @include media(desktop up) {
                    top: $gallery-offset;
                  }
                }

                app-gallery-table {
                  .gallery-table-wrapper {
                    .admin-search-component {
                      @include sticky();
                      @include media(desktop up) {
                        background: $alice-gray;
                        top: $gallery-offset;
                        padding: 5px;
                        background: $base;
                        border: $border-width solid $border-color;
                        border-radius: $box-border-radius;
                        &:before {
                          content: '';
                          width: calc(100% + 2px);
                          background: $alice-gray;
                          height: 40px;
                          position: absolute;
                          top: -41px;
                          left: -1px;
                        }
                      }
                    }
                    .my-admin-data-wrapper {
                      @include media(desktop up) {
                        border: none;
                        background: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .my-admin-data-wrapper {
          background: $base;
          border: 1px solid $border-color;
          box-sizing: border-box;
          @include media(mobile) {
            height: calc(100% - 58px);
          }
        }
      }

      &__half {
        .box {
          position: relative;
          overflow: visible;
          &__content {
            overflow: unset;
          }
        }

        &--sticky {
          .box {
            @include media(desktop up) {
              @include sticky();
              top: $boxes-offset;
            }
          }
        }
      }

      &__inner {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &--tutorial {
    .#{$m} {
      &__content {
        .tutorial-wrapper {
          width: 100%;
          .filters {
            .filters-wrapper {
              padding: 10px 0;
              @include min-screen($md) {
                padding: 20px 0;
              }
            }
          }
          .jakob-muller-table {
            @include box-primary(false);
            .table-data-row {
              height: 94px;
              .preview-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 64px;
                width: 64px;
                margin-right: 15px;
                @include box-primary(false);
                background-color: $base;
                border-radius: 3px;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
              }
            }
            .text {
              .approximately {
                font-size: 12px;
                line-height: 26px;
                color: $black-mine-shaft;
                margin-top: -20px;
              }
              .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 26px;
                color: $black-mine-shaft;
              }
            }
          }
        }
      }
    }
  }

  &--library {
    .#{$m} {
      &__content {
        height: auto;
        @include media(mobile) {
          height: auto;
        }

        .filters-article-templates {
          .filters-wrapper {
            padding: 10px 0;
          }
        }
        .library-data-wrapper {
          height: calc(100% - 85px);
          background: $base;
          border: 1px solid $border-color;
          box-sizing: border-box;
          border-radius: 5px;
          overflow: hidden;
          @include max-screen($md - 1) {
            height: calc(100% - 58px);
          }
        }

        .main-content {
          display: flex;
          flex-direction: column;
          height: auto;
          min-height: calc(100vh - #{$header-plus-footer-height});
          flex: 1;

          .sticky-holder {
            z-index: 2;
            @include media(desktop up) {
              top: $main-offset;
              &:before {
                content: '';
                height: 24px;
                width: 100%;
                top: -24px;
                position: absolute;
                background: $alice-gray;
              }
            }

            app-header-bar {
              @include media(desktop up) {
                @include sticky();
                top: $main-offset;
                &:after {
                  content: '';
                  position: absolute;
                  height: 10px;
                  width: 100%;
                  left: 0;
                  bottom: -11px;
                  background: linear-gradient(180deg, $alice-gray, transparent);
                }
              }
            }
          }

          app-l-asset,
          app-l-article-details {
            height: 100%;
            width: 100%;
          }
          app-saved-templates-table {
            flex: 1;
          }

          .my-library-data-wrapper {
            background: $base;
            border: 1px solid $border-color;
            box-sizing: border-box;

            &.article-content-version {
              height: auto;
            }
          }
        }
      }
    }
  }

  &--primary {
    .#{$m} {
      &__content {
        //must have min height to have it use space(table has innerscroll)
        min-height: calc(100vh - #{$navbar-mobile-height} - #{$footer-height} - #{$main-content-padding});
        @include media(tablet up) {
          min-height: calc(100vh - #{$header-height} - #{$footer-height} - #{$main-content-padding});
        }
        .main-content {
          min-height: calc(100vh - #{$navbar-mobile-height} - #{$footer-height} - #{$main-content-padding});
        }
      }
    }
  }

  &--layout {
    .#{$m} {
      &__content {
        height: auto;
        @include media(mobile) {
          height: auto;
        }

        .main-content {
          display: flex;
          flex-direction: column;
          height: auto;
          min-height: calc(100vh - #{$header-plus-footer-height});
          flex: 1;
          app-widgets-landing {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;
          }
        }
      }
    }
  }

  &--with-sidebar {
    .#{$m} {
      &__content {
        height: auto;
        @include media(mobile) {
          height: auto;
        }

        .sidebar {
          flex: 1;
          @include min-screen($lg) {
            flex: 0 0 $sidebar-width;
          }
        }

        .main-content {
          display: flex;
          flex-direction: column;
          height: auto;
          min-height: calc(100vh - #{$header-plus-footer-height});
          flex: 1;
        }
      }
    }
  }

  &--notification-configuration {
    .#{$m} {
      &__content {
        flex-direction: column;
        height: auto;
        @include media(mobile) {
          height: auto;
        }
      }
    }
  }
}
