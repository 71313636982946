// Forms styling
// --------------------------------------------
$m: form;

// Normalize default fields
fieldset {
  margin: 0;
  padding: 0;
}

input {
  @include border-box();
}

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

// Remove orange outline in mobile Chrome
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

// Form styling
.#{$m} {
  position: relative;

  // Fieldset (wrap)
  &__fieldset {
    position: relative;
    margin: 0 0 20px 0;
    padding: 0;
    border: 0;
  }

  &__row {
    display: flex;

    &--column_on_mobile {
      @include media(mobile tablet) {
        flex-direction: column;
      }

      .form__input {
        &:first-of-type {
          @include media(mobile tablet) {
            margin-right: 0;
          }
        }
      }
    }
    &--inputs-without-labels {
      align-items: flex-start;
      .#{$m} {
        &__remove {
          margin-top: 0;
        }
      }
    }
  }

  &__input {
    flex: 1;
    &:first-of-type {
      margin-right: 20px;
    }
  }

  &__remove {
    @include flex-center-center;
    height: $input-height;
    width: 42px;
    margin: 22px 0 0 10px;
    background: $base;
    border: 1px solid $heather-gray;
    box-sizing: border-box;
    border-radius: 5px;
    @include transition(0.25s);

    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    svg-icon {
      svg {
        path {
          @include transition(0.25s);
        }
      }
    }

    &:hover {
      border: 1px solid $cinnabar-red;

      svg-icon {
        svg {
          path {
            fill: $cinnabar-red;
          }
        }
      }
    }
  }
}
