@import '_vars';
@import '_mixin';

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

label {
  cursor: pointer;
}

p.no-data {
  font-weight: $bold;
  font-size: $body;
  line-height: 20px;
  color: $black-mine-shaft;
  margin-bottom: 0px;
  text-align: center;
}

.mat-mdc-menu-panel {
  background-color: $base;
  &.alert-menu {
    width: 360px;
    max-width: 100%;
    margin-top: 5px;
  }
  &.user-profile-menu {
    margin-top: 5px;
    .user-profile-link-wrapper {
      width: 100%;
      .user-profile-link {
        height: 60px;
        min-width: 170px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        border-bottom: 1px solid $border-color;
        font-weight: $bold;
        font-size: $label;
        line-height: 18px;
        color: $black-mine-shaft;
        cursor: pointer;
        @include transition(0.25s);
        &:last-of-type {
          border-bottom: 0px;
        }

        &:hover {
          background: $catskill-white;
        }
      }
    }
  }

  &.filter-menu {
    @include box-primary(true);
    width: $calendar-width;
    max-width: $calendar-width;
    min-width: $calendar-width;
    margin-top: -5px;
    border-top-left-radius: 0;
    box-shadow: unset;

    .title {
      font-weight: $bold;
      font-size: $caption;
      line-height: 15px;
      color: $black-mine-shaft;
    }

    .actions-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: $elements-spacing;
      gap: $inner-spacing;
      .clear {
        font-weight: $bold;
        font-size: $caption;
        line-height: 15px;
        color: $jm-red;
      }
    }
  }
  &.jm-table-action-menu {
    background: $base;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 20px;
    .mat-mdc-menu-content {
      div.option {
        height: 25px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          &:not(.disable) {
            .option-icon {
              svg path {
                fill: $mantis-green;
              }
            }
          }
        }
        &.disable {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
          .option-icon {
            svg path {
              fill: $black-mine-shaft;
            }
          }
          span.label {
            color: $black-mine-shaft;
          }
        }
        &:last-of-type {
          margin-bottom: 0px;
        }

        &.delete {
          &:hover {
            .option-icon {
              svg path {
                fill: $cinnabar-red;
              }
            }
          }
        }

        .option-icon {
          margin-right: 11px;

          svg {
            width: 16px;
            height: 16px;
            path {
              fill: $trout;
              @include transition(0.2s);
            }
          }
        }
        span.label {
          font-weight: $bold;
          font-size: $caption;
          line-height: 15px;
          color: $black-mine-shaft;
        }
      }
    }
    &.sort-wrapper {
      width: $calendar-width;
      max-width: $calendar-width;
      min-width: $calendar-width;

      .mat-mdc-menu-content {
        .title {
          font-weight: $bold;
          font-size: $caption;
          line-height: 15px;
          color: $black-mine-shaft;
        }

        .actions-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $elements-spacing;
          .clear {
            font-weight: $bold;
            font-size: $caption;
            line-height: 15px;
            color: $jm-red;
          }
        }
      }
    }
    &.component-dropdown {
      width: 241px;
      background: $base;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      padding: 0 15px;
      .mat-mdc-menu-content {
        .component-option {
          display: flex;
          align-items: center;
          height: 42px;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          color: $black-mine-shaft;
          cursor: pointer;
          .component-icon {
            width: 15px;
            margin-right: 10px;
          }
          &:hover {
            color: $mantis-green;
            .component-icon svg path {
              fill: $mantis-green;
            }
          }
        }
      }
    }
    &.widget-role-menu {
      padding: 0;
    }
  }
  &.keyword-menu {
    max-width: 450px;
    padding: 0;
  }

  &.dashboard-menu {
    .mat-mdc-menu-content {
      padding: 0;
    }
  }
  &.columns-selection {
    margin-right: -4px;
    .mat-mdc-menu-content {
      width: calc(100vw - 35px);
      .option {
        height: $input-height;
        padding: 0 $inner-spacing;
        display: flex;
        align-items: center;
        font-size: $caption;
        font-weight: $bold;
        color: $black-mine-shaft;

        &:hover {
          cursor: pointer;
          color: $mantis-green;
        }
      }
    }
  }
}

.background-home-page {
  background: linear-gradient(180deg, #eff3f6 58.81%, #e6ebef 100%);
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  width: 100%;
  height: 690px;
  border-radius: 0 0 100% 100%;

  @include media(mobile) {
    display: none;
  }
  @include media(tablet up) {
    height: 70vh;
  }
}

.button-more-options {
  @include box-primary;
  @include flex-center-center;
  height: 36px;
  width: 36px;
  @include transition(0.2s);
  cursor: pointer;
  svg path {
    @include transition(0.2s);
  }

  .option-icon {
    display: flex;
  }
  &.disabled {
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid $mantis-green;
  }

  &:hover:not(.disabled) .option-icon {
    svg path {
      fill: $mantis-green;
    }
  }
}

.mobile-box-shadow {
  height: 100vh;
  width: 100%;
  background: $black-mine-shaft;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.cdk-drag-preview {
  box-sizing: border-box;
  display: flex;
  &.display-with-remove {
    display: flex;
    align-items: flex-end;
    background-color: $base;
    .drag-icon {
      display: flex;
      align-items: center;
      margin-right: 14px;
      cursor: pointer;
      height: 48px;
    }
    app-sb-string-input {
      width: calc(100% - 22px);
    }
    .button-more-options {
      height: 42px;
      width: 42px;
      margin-left: 10px;
    }
    .form__remove {
      margin: 0 !important;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.selectable {
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
}

.mat-mdc-menu-content,
.mat-mdc-tab {
  letter-spacing: unset !important;
}

.has-tooltip {
  z-index: 100;
}

.p-tooltip {
  margin-top: -5px !important;
  z-index: 1199 !important;
  .p-tooltip-text {
    @include flex-center-center;
    background: $base;
    border: $border-width solid $mantis-green;
    box-sizing: border-box;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    font-size: $caption;
    color: $black-mine-shaft;
    min-width: 50px;
    min-height: 35px;
    position: relative;
    padding: $inner-spacing $elements-spacing;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 10px);
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: 99%;
      border-color: $base transparent transparent transparent;
      border-width: 10px;
    }

    &:before {
      top: 100%;
      border-color: $mantis-green transparent transparent transparent;
      border-width: 10px;
      margin-top: 1px;
    }
  }
  .p-tooltip-arrow {
    border-top-color: $mantis-green !important;
    border-right-color: $mantis-green !important;
    border-bottom-color: $mantis-green !important;
    border-left-color: $mantis-green !important;
  }

  &.red {
    .p-tooltip-text {
      border: $border-width solid $jm-red !important;

      &:before {
        border-color: $jm-red transparent transparent transparent;
      }
    }
  }

  &.min-width-300 {
    .p-tooltip-text {
      min-width: 250px;
    }
  }
}
