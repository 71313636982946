@import '_vars';

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: $base-gray;
    margin: 1px 0px 0 0;
  }
  .mat-slide-toggle-bar {
    background-color: $smothie-gray;
  }
}
.mat-slide-toggle-thumb {
  background-color: $smothie-gray;
}
.mat-slide-toggle-bar {
  background-color: $alice-gray;
  height: 19px !important;
  border-radius: 11px !important;
}
.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background: transparent;
}
.mat-slide-toggle-thumb-container {
  top: -2px !important;
}
