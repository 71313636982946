//helper classes

//override
.cdk-overlay-pane {
  max-width: 97vw !important;

  &--image_gallery-modal {
    @include media(tablet up) {
      min-width: $md;
    }
    @include min-screen($lg) {
      min-width: $lg;
    }
    @include media(desktop) {
      min-width: $desktop;
    }
  }

  .mat-mdc-menu-panel {
    overflow: visible;
    min-height: unset;
    min-width: unset;
    max-height: unset;
    max-width: unset;
    &.admin-dropdown-links {
      min-height: initial;
    }
    &.admin-filters {
      max-width: 100%;
    }
  }

  .mat-dialog-container {
    @include media(mobile tablet) {
      overflow: auto;
      max-height: 100vh;
    }
    @include media(mobile) {
      height: auto;
      padding: 15px;
    }
  }
}

.chart-dialog-container {
  max-width: 100vw !important;
}

//svg is an inline element. inline elements leave white-space.
svg-icon {
  svg {
    display: block;
  }
}

//resolves overflow problems

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  min-width: 100%;
  max-width: 100%;
}

.link {
  color: $jm-red;
  transition: $anim-time all ease;

  &:hover {
    color: $mantis-green;
  }
}
