// cards module
$m: jm-card;

.#{$m} {
  &--library-box {
    @include box-primary;
    .#{$m} {
      &__article-id {
        padding: 0 20px;
        height: 60px;
        background: linear-gradient(180deg, $base 0%, #f7f9fb 100%);
        border-bottom: 1px solid $border-color;
        box-sizing: border-box;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $label;
        line-height: 18px;
        color: $black-mine-shaft;
        .delete-icon {
          svg {
            path {
              color: $main-text-color;
              @include transition(0.25s);
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: $cinnabar-red;
              }
            }
          }
        }
      }

      &__article-name {
        height: auto;
        hyphens: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding: 20px;
        font-weight: bold;
        font-size: $title;
        line-height: 26px;
        color: $black-mine-shaft;
        margin-bottom: 15px;
      }

      &__article-image-wrapper {
        display: flex;
        padding: 0 20px;
        .image-wrap {
          border: 1px solid $border-color;
          width: 72px;
          height: 72px;
          margin-right: 12px;
          position: relative;
          z-index: 2;
          img {
            &.article-image {
              cursor: pointer;
              display: block;
              margin: 0 auto;
              width: 100%;
              height: 100%;
            }
            &.image-not-found {
              pointer-events: none;
              cursor: default !important;
              &:hover {
                border: none !important;
              }
            }
          }
          .pdf-preview {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .pdf-icon {
              width: 50px;
              height: 50px;
            }
            div.pdf-title {
              font-size: 12px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: $black-mine-shaft;
              display: inline-block;
              width: calc(100% - 7px);
              overflow: hidden !important;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 0 auto;
            }
          }
          img.preview-icon {
            width: 16px;
            height: 16px;
            position: absolute;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
            z-index: 1;
          }
          &:hover {
            border-color: $mantis-green;
            &:after {
              content: '\A';
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.6);
            }
          }
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }

  &--home {
    text-align: center;
    opacity: 0;
    animation: fadeIn 0.5s ease-in both;
    border-radius: 5px;
    overflow: hidden;
    @include transition(0.25s);
    @include max-screen($md - 1) {
      margin-bottom: 10px;
      @include flexbox();
      @include box-primary;
      @include justify-content(flex-start);
    }

    @include min-screen($md) {
      margin-right: 15px;
      width: 160px;
      border: 1px solid $mystic-gray;
    }

    @include min-screen($desktop) {
      margin-right: 20px;
      width: 230px;
    }

    &:hover {
      cursor: pointer;
      @include min-screen($md) {
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.09);
      }

      .#{$m} {
        &__text-wrapper {
          .title {
            color: $jm-red;
          }
        }
      }
    }

    &.active {
      .#{$m} {
        &__img-wrapper {
          .normal-icon {
            display: block;
          }

          .disabled-icon {
            display: none;
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
        pointer-events: none;
      }

      .#{$m} {
        &__text-wrapper {
          .title,
          .description {
            color: $button-disabled-text;
          }
        }

        &__img-wrapper {
          &:hover {
            cursor: not-allowed;
          }

          .normal-icon {
            display: none;
          }

          .disabled-icon {
            display: block;
          }

          img {
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    &:last-of-type {
      margin-right: 0px;
    }
    .#{$m} {
      &__img-wrapper {
        cursor: pointer;
        background: $base;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include min-screen($md) {
          @include flex-center-center;
          height: 0;
          padding-bottom: 100%;
          position: relative;
          box-shadow: inset 0 -5px 0px -4px $border-color;
        }

        @include max-screen($md - 1) {
          @include flex(0 0 auto);
          min-height: 60px;
          max-width: 182px;
          padding: 0 15px 0 20px;
        }

        img {
          @include max-screen($md - 1) {
            max-width: 30px;
          }
          @include min-screen($md) {
            @include centerer();
          }
        }
      }

      &__text-wrapper {
        width: 100%;
        display: block;
        background: $catskill-white;
        padding: 20px 10px;

        @include max-screen($md - 1) {
          @include flex(0 0 auto);
          @include flexbox();
          @include flex-direction(row);
          @include align-items(center);
          @include justify-content(flex-start);
          padding: 0 10px;
          text-align: left;
        }
        @include min-screen($md) {
          margin: 0 auto;
          min-height: 138px;
          height: 100%;
        }

        p.title {
          font-weight: $extra_bold;
          font-size: $title;
          line-height: 23px;
          color: $black-mine-shaft;
          padding: 0;
          margin: 0;
          @include transition(0.25s);

          @include min-screen($md) {
            padding: 0 0 20px 0;
          }
        }
        p.description {
          font-size: $caption;
          line-height: 18px;
          color: #707374;
          margin: 0 auto;
          max-width: 200px;
          text-align: center;
          @include max-screen($md - 1) {
            display: none;
          }
        }
      }
    }
  }

  &--instructions {
    position: relative;
    display: flex;
    flex-direction: column;
    @include max-screen($md - 1) {
      margin-bottom: 40px;
      flex-direction: row;
      justify-content: flex-start;
    }

    .#{$m} {
      &__image {
        @include box-primary;
        width: 140px;
        height: 140px;
        margin: 0 auto 20px;
        border-radius: 50%;
        @include flex-center-center;
        position: relative;
        z-index: 2;

        @include max-screen($md - 1) {
          margin-right: 20px;
        }

        &:after,
        &:before {
          z-index: 1;
        }
      }

      &__data {
        @include max-screen($md - 1) {
          margin-top: 20px;
        }
      }

      &__title {
        height: 37px;
        width: 140px;
        margin: 0 auto;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: $jm-red;
        @include min-screen($md) {
          text-align: center;
        }
      }

      &__message {
        width: 140px;
        margin: 0 auto;
        font-size: 12px;
        line-height: 18px;
        color: $main-text-color;
        @include min-screen($md) {
          text-align: center;
        }
      }
    }
  }
}
