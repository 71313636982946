@import '_vars';
@import '_mixin';

$alert-icon-height: 24px;
$alert-icon-width: 24px;
$popup-footer-height: 49px;
$scrollbar-width: 18px;

.alert-content {
  overflow-y: auto;
  padding: 0 $inner-spacing 0 $md-spacing;
  text-align: center;
  position: relative;
  min-height: 200px;
  max-height: 555px;

  @include media(mobile) {
    height: calc(100vh - #{$navbar-mobile-height} - #{$popup-footer-height} - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    text-align: center;
    min-height: unset;
    max-height: unset;
    padding: $elements-spacing 4px $elements-spacing 8px;
    background-color: $base;
    overflow: hidden;
  }

  .header-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    width: calc(100% - #{$scrollbar-width});
    h1.title {
      font-weight: $bold;
      font-size: $body;
      line-height: $lh-small;
      color: $black-mine-shaft;
      margin-bottom: 0;
    }
    div.button-more-options {
      @include box-primary;
      @include flex-center-center;
      height: $more-options-btn-sizing;
      width: $more-options-btn-sizing;
      cursor: pointer;
      .option-icon {
        display: flex;
        svg path {
          @include transition(0.25s);
        }
      }
      &:hover {
        border: $border-width solid $mantis-green;
        .option-icon {
          svg path {
            fill: $mantis-green;
          }
        }
      }
    }
  }
  div.more-options-wrapper {
    min-height: 89px;
    max-width: 302px;
    width: 100%;
    position: absolute;
    top: 70px;
    background: $base;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
    border-radius: $box-border-radius;
    z-index: 10000;
    padding: 23px 17px;

    @include media(mobile) {
      top: 85px;
      right: 21px;
    }

    @include max-screen(500px) {
      max-width: calc(100% - 29px);
    }

    div.option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 25px;

      .option-icon {
        margin-right: 13px;
        svg path {
          stroke: $black-mine-shaft;
          @include transition(0.25s);
        }
      }
      span {
        font-weight: $bold;
        font-size: $caption;
        line-height: 15px;
        color: $black-mine-shaft;
        @include transition(0.25s);
      }
      &:hover {
        span {
          color: $main-text-color;
        }
        .option-icon {
          svg path {
            stroke: $mantis-green;
          }
          &.alert-configuration {
            svg path {
              fill: $mantis-green;
            }
          }
        }
      }
      &.disable {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        .option-icon {
          svg path {
            stroke: $black-mine-shaft;
          }
        }
        span {
          color: $black-mine-shaft;
        }
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 6px;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:before {
      top: -21px;
      border-color: transparent transparent $base transparent;
      border-width: 10px;
      margin-top: 1px;
    }
  }
  cdk-virtual-scroll-viewport {
    height: 100%;
    width: 100%;
    min-height: 350px;
    .cdk-virtual-scroll-content-wrapper {
      .alert-detail {
        border: $border-width solid $border-color;
        box-sizing: border-box;
        border-radius: $box-border-radius;
        max-width: 312px;
        min-height: $row-height;
        margin-bottom: $inner-spacing;
        display: flex;
        align-items: center;
        padding: $inner-spacing;
        background: $catskill-white;
        transition: $anim-time border-color ease-in-out;

        @include media(mobile) {
          max-width: unset;
        }

        &:hover {
          border-color: $mantis-green;
          cursor: pointer;
        }

        .alert-icon {
          width: $alert-icon-width;
          height: $alert-icon-height;
          display: flex;
          justify-content: center;
        }
        .notification-info {
          margin-left: $inner-spacing;
          color: $black-mine-shaft;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 0;

          .message {
            font-size: $caption;
            line-height: $lh-default;
            display: flex;
            align-items: center;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0;
          }
          .time-ago {
            font-size: $caption_small;
            line-height: $lh-default;
            text-align: left;
            margin-top: $xs-spacing;
            color: $main-text-color;
            margin: 0;
          }
        }
        &.unread {
          background: $base;
          cursor: pointer;
          .notification-info {
            .message {
              font-weight: $bold;
            }
          }
        }
      }
    }
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 $scrollbar-color;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $nevada-gray;
    }
  }
}
