@import '_vars';

.progress {
  height: $progress-bar-height;
  border-radius: 100px !important;
  background-color: $border-color !important;
  box-shadow: inset 0 0 0 1px $mystic-gray;
  position: relative;
  .bg-success {
    background-color: $mantis-green !important;
  }
  .bg-secondary {
    background-color: $border-color !important;
  }
  .bar-text {
    font-weight: $bold;
    font-size: $caption;
    line-height: 1.2;
    color: $black-mine-shaft;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .remaining-time {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: $progress-bar-height;
    @include flex-center-center;
  }

  &.success-100 {
    .progress-bar {
      background-color: $success-100 !important;
    }
  }
  &.success-90 {
    .progress-bar {
      background-color: $success-90 !important;
    }
  }
  &.success-80 {
    .progress-bar {
      background-color: $success-80 !important;
    }
  }
  &.success-70 {
    .progress-bar {
      background-color: $success-70 !important;
    }
  }
  &.success-60 {
    .progress-bar {
      background-color: $success-60 !important;
    }
  }
  &.success-50 {
    .progress-bar {
      background-color: $success-50 !important;
    }
  }
  &.success-40 {
    .progress-bar {
      background-color: $success-40 !important;
    }
  }
  &.success-30 {
    .progress-bar {
      background-color: $success-30 !important;
    }
  }
  &.success-20 {
    .progress-bar {
      background-color: $success-20 !important;
    }
  }
  &.success-10 {
    .progress-bar {
      background-color: $success-10 !important;
    }
  }
}
