@import '_vars';
@import '_mixin';

@keyframes pulse {
  0% {
    @include scale(1);
    opacity: 1;
  }
  50% {
    @include scale(1.15);
    opacity: 0.5;
  }
  to {
    @include scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1.05, 1.05);
  }
}

@keyframes fadeInHome {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes jm-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
