.user-profile__wrapper {
  .quick-form {
    .controls {
      display: flex;
      gap: $section-spacing;
      @include media(mobile) {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }
  }
}
