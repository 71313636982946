@import '_vars';

.chip {
  background: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #d6dee4;
  border-radius: 5px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  box-sizing: border-box;
  margin: 0 10px;

  &.success {
    color: $mantis-green;
    border-color: $mantis-green;
  }

  &.error {
    color: $jm-red;
    border-color: $jm-red;
  }

  &.warning {
    color: $saffron;
    border-color: $saffron;
  }

  .chip__img {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
}
