.component-details-wrapper {
  position: relative;
  height: 100%;
  .form-field-wrapper {
    margin-bottom: 20px;
    .edit-table-btn {
      display: flex;
      border: 1px solid $border-color;
      background: $catskill-white;
      border-radius: 5px;
      padding: 0 5px 0 15px;
      cursor: pointer;
      font-weight: $bold;
      font-size: $label;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $black-mine-shaft;
      @include transition(0.25s);
      height: 42px;
      .component-icon {
        margin-right: 15px;
        svg path {
          fill: $main-text-color;
        }
      }
      &:hover {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.04);
        background: $base;
        border: 1px solid $mantis-green;
      }
    }
    &.slide-toggle {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: $label;
      line-height: 18px;
      color: $black-mine-shaft;
      app-sb-slide-toggle {
        margin: 5px 0 0 20px;
      }
    }
    &.half-width {
      width: 50%;
    }
    &.display-with-remove {
      display: flex;
      .drag-icon {
        display: flex;
        align-items: center;
        margin-right: 14px;
        cursor: pointer;
        height: 48px;
      }
    }
  }
  .form-field-flex {
    display: flex;
    gap: 10px;
    align-items: center;
    .form-field-wrapper {
      display: block;
      flex: 1;
      margin-bottom: 20px;

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
  .options-wrapper {
    &.display-with-remove {
      display: flex;
    }
    h1.option-title {
      font-weight: bold;
      font-size: $body;
      line-height: 20px;
      color: $black-mine-shaft;
      margin: 15px 0 25px 0;
    }
    app-sb-string-input {
      width: calc(100% - 22px);
    }
    .button-more-options {
      margin-left: 10px;
      height: 42px;
      width: 42px;
      margin-top: 25px;
    }
  }
  .button-wrapper.options-button {
    margin-top: 20px;
  }
}
