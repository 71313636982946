@import '_vars';
@import '_mixin';

.panel {
  background: $base;
  border: $border-width solid $border-color;
  border-radius: $box-border-radius;
  padding:$section-spacing;
  display: flex;
  flex-direction: column;

  &__header {
    background: linear-gradient(180deg, $base 0%, #f7f9fb 100%);
    height: $box-header-height;
    min-height: $box-header-height;
    padding: 0 $section-spacing;
    display: flex;
    align-items: center;
    border-radius: $box-border-radius $box-border-radius 0 0;
    border-bottom: $border-width solid $border-color;
    margin: -#{$section-spacing} -#{$section-spacing} 0 -#{$section-spacing};
    font-weight: $bold;
  }

  &__main {
    flex-grow: 1;
    padding-top: $section-spacing;
    padding-bottom: $section-spacing;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: $section-spacing;
    border-top: $border-width solid $border-color;
    margin: 0 -#{$section-spacing} -#{$section-spacing} -#{$section-spacing};
    gap: $inner-spacing;
  }
}
