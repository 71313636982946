@import '_vars';

.map-component-wrapper {
  display: flex;

  .map-wrapper {
    height: calc(100vh - 260px);
    width: 100%;
    position: relative;
    overflow: hidden;

    .selected-location {
      .map-header {
        &.with-selected-location {
          @include max-screen($md) {
            width: 100%;
          }
        }
      }
    }
  }
  .dashboard-navigation {
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    z-index: 100;
    width: auto;
  }
  .map-svg-wrapper {
    position: relative;
    #map {
      width: 100%;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      svg {
        background-color: transparent;
      }
      path {
        fill: rgba(170, 185, 195, 0.5);
        stroke: rgba(255, 255, 255, 0.4);
        stroke-width: 0.5px;
      }

      circle {
        border: 2px solid #eff3f6;
        box-sizing: border-box;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
      }
    }

    .jm-loader {
      @include centerer();
    }

    div.tooltip {
      background: $base;
      border-radius: 5px;
      padding: 5px 10px;
      opacity: 0.9;
      position: absolute;
      pointer-events: none !important;
      transform: translate(-50%, -100%);
      .tooltip-label {
        color: $black-mine-shaft;
        font-size: $caption;
        font-weight: $bold;
      }
      &.active {
        border: 1px solid $mantis-green;
      }
      &.inactive {
        border: 1px solid $jm-red;
      }
    }

    .hidden {
      display: none !important;
    }

    .arrow-down {
      content: '';
      position: absolute;
      left: calc(50% - 8px);
      top: calc(100%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $base;
      clear: both;
      clear: both;
      &.active {
        border-top: 1px solid $mantis-green;
      }
      &.inactive {
        border-top: 1px solid $jm-red;
      }
    }

    .arrow-down-back {
      content: '';
      position: absolute;
      left: calc(50% - 10px);
      top: calc(100%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      clear: both;
      &.active {
        border-top: 1px solid $mantis-green;
      }
      &.inactive {
        border-top: 1px solid $jm-red;
      }
    }
  }
}
