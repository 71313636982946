//jm-styling
@import '_vars';
@import '_mixin';

.mat-dialog-container {
  background-color: $base;
  padding: 0 !important;
}
.jm-modal {
  padding: 25px 20px 30px 20px;
  position: relative;
  width: 100%;

  @include media(tablet up) {
    width: 360px;
    padding: 25px;
  }

  &__holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 0 30px 0;
    .close-icon-dialog {
      position: relative;
      bottom: 5px;

      svg path {
        @include transition(0.2s);
      }

      &:hover {
        cursor: pointer;
        svg path {
          fill: $mantis-green;
        }
      }
    }

    .title {
      font-size: $body;
      font-weight: $bold;
      color: $black-mine-shaft;
      line-height: 1.5;

      @include media(tablet up) {
        font-size: $heading;
      }
    }
  }
  .form-field-wrapper {
    margin-bottom: 20px;
  }
  .action-buttons {
    display: flex;
    jm-button {
      min-width: 58px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
